import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import PlusOneIcon from "@mui/icons-material/PlusOne";
import {
  Typography,
  Button,
  Drawer,
  Divider,
  TextField,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  Autocomplete,
  Box,
  InputAdornment,
  OutlinedInput,
  Slider,
  Snackbar,
  SnackbarContent,
  Alert,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import axios from "axios";
import { education_list } from "../educationlist";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import _debounce from "lodash/debounce";
import Questionpop from "./Questionpop";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const countryCodes = [
  { label: "US (+1)", value: "+1" },
  // Add other countries here
  // { label: 'UK (+44)', value: '+44' },
  // ...
];

const PrettoSlider = styled(Slider)({
  color: "#006CB7",
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 18,
    width: 18,
    backgroundColor: "#006CB7",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 26,
    height: 26,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#006CB7",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

// const baseURL = process.env.REACT_APP_BASEURL;

const monthss = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

const CurrentLocationOptions = ["Option 1", "Option 2"];
const AddCandidateDrawer = ({
  isDrawerOpen,
  setDrawerOpen,
  questionsrequired,
}) => {
  const eighteenYearsAgo = dayjs().subtract(18, "year");
  const [gender, setGender] = useState("");
  const [months, setMonths] = useState("");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [uploadDate, setUploadDate] = useState("");
  const [preferredLocations, setPreferredLocations] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("preferred");
  const [radius, setRadius] = useState(0);
  const [highEducation, setHighEducation] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [countries, setCountries] = useState([]);
  const [mobile, setMobile] = useState("");
  const [currentAddress, setCurrentAddress] = useState("");
  const [years, setYears] = useState("");
  const [currentEmployer, setCurrentEmployer] = useState("");
  const [currentSalary, setCurrentSalary] = useState("");
  const [expectedSalary, setExpectedSalary] = useState("");
  const [locations, setLocations] = useState([]);
  const [linkedin, setLinkedin] = useState("");
  const [portfolio, setPortfolio] = useState("");
  const { id } = useParams();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [currrentLocation, setCurrentLocation] = useState(null);
  const [preferredLocation, setpreferedLocation] = useState([]);
  const [inputCurrentLocation, setInputCurrentLocation] = useState("a");
  const [cityLocation, setCityLocation] = useState("");
  const [preferredArr, setPreferredArr] = useState([]);
  const getToken = useSelector((state) => state.user.token);
  const [emailError, setEmailError] = useState("");
  const [fullnameError, setFullnameError] = useState("");
  const [skillsError, setSkillsError] = useState("");
  const [getCity, setCityLoc] = useState("");
  const [getprefered, setprefcityLoc] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [FileError, setFileError] = useState("");
  const [preferredLocationError, setPreferredLocationError] = useState("");
  const [emailalreadyexists, setEmailalreadyexists] = useState(false);
  const [linkedinError, setLinkedinError] = useState("");
  const [portfolioError, setPortfolioError] = useState("");
  const [profileErrorHelperText, setPortfolioErrorHelperText] = useState("");
  const [SkillsOption, setSkillsOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(SkillsOption);
  const [isCountryCodeSelected, setIsCountryCodeSelected] = useState(false);
  const [mobileError, setMobileError] = useState("");
  const [countryCodeError, setCountryCodeError] = useState("");
  const [janewaliSkill, setJannewaliSkill] = useState([]);
  const [quesAnsArray, setQuesAnsArray] = useState([]);

  const [quesOpenPopup, setQuesOpenPopup] = useState(false);

  const [finalsummit, setFinalSummit] = useState(false);
  const [textFields, setTextFields] = useState({});
  const [radioValues, setRadioValues] = useState({});

  const handleQuesPopupOpen = () => {
    setQuesOpenPopup(true);
  };

  const [HighEducationOptions, setHighEducationOptions] = useState([]);
  const [workAuthorizationOptions, setWorkAuthorizationOptions] = useState([]);
  const [selectedworkAuthorization, setSelectedworkAuthorization] =useState("");
  const [DateAvailable, selectedDateAvailable] = useState("");
  const [NoticePeriod,setNoticePeriod] = useState("");
  const [lastworkingday, setLastworkingday] = useState("");
  const [selectedCityId, setSelectedCityId] = useState(null);
  const [preferredLocationIds, setPreferredLocationIds] = useState([]);


  const handleQuesPopupClose = () => {
    const a = quesAnsArray.map((question) => {
      return {
        question_types: question.question_types,
        question_id: question.question_id,
        ques_ans: question.ques_ans,
        answer_text: "",
        // answer_id:null,
        error: null,
      };
    });
    setQuesAnsArray(a);
    setQuesOpenPopup(false);
  };


  const validate = () => {
    let isValid = true;
    let updateQuesArr = [...quesAnsArray];
    updateQuesArr.forEach((item, index) => {
      if (item.question_types === 1) {
        if (item.answer_text === "") {
          item.error = "This field is required";
          isValid = false;
        } else if (item.answer_text.length >= 501) {
          item.error = "Maximum 500 characters allowed";
          isValid = false;
        } else {
          item.error = null;
        }
      } else if (item.question_types === 2) {
        if (item.answer_text === "") {
          item.error = "This field is required";
          isValid = false;
        } else if (item.answer_text.length >= 13) {
          item.error = "Maximum 12 digits allowed";
          isValid = false;
        } else {
          item.error = null;
        }
      } else if (item.question_types === 3) {
        if (item.answer_text === "") {
          item.error = "This field is required";
          isValid = false;
        } else {
          item.error = null;
        }
      }
    });
    setQuesAnsArray(updateQuesArr);
    return isValid;
  };

  const handleQuessummit = () => {
    setFinalSummit(true);

    if (!validate()) {
      return;
    }

    let destCompare = "";
    if (currrentLocation) {
      destCompare = [...currrentLocation];
      const compareIndex = currrentLocation.findIndex(
        (e) => e.city === getCity
      );
      destCompare.splice(compareIndex, 1);
      destCompare.unshift(currrentLocation[compareIndex]);
    }

    const QuestArr = quesAnsArray.map((quesAns) => {
      delete quesAns.ques_ans;
      delete quesAns.error;
      delete quesAns.question_types;
      return quesAns;
    });

    const formData = new FormData();

    formData.append("resume", file);
    // Append other fields to the FormData object
    formData.append("job_id", id);
    formData.append("email", email);
    formData.append("fullname", fullname);
    formData.append("mobile", mobile);
    formData.append("address", currentAddress);
    formData.append("gender", gender);
    if (selectedDate !== null) {
      formData.append("dob", selectedDate);
    }
    // formData.append("dob", selectedDate);
    formData.append("countryCode", "+1 " + countryCode);
    formData.append("year", years);
    formData.append("month", months);
    formData.append("current_employer", currentEmployer);
    formData.append("CTC", currentSalary);
    formData.append("Exp_CTC", expectedSalary);
    // Append other fields as needed
    // formData.append("radius", radius);
    formData.append(
      "radius",
      selectedLocation === "anyLocation" ? null : radius ? radius : null
    );
    formData.append("highest_educ", highEducation ? highEducation : 1);
    formData.append("work_authorization", selectedworkAuthorization ? selectedworkAuthorization :1);
    formData.append("expected_joining_date", DateAvailable ? DateAvailable : null);
    formData.append("notice_period", NoticePeriod ? NoticePeriod : null);
    formData.append("last_working_day",lastworkingday ? lastworkingday : null);
    formData.append("linkedin", linkedin);
    formData.append("portfolio", portfolio);
    formData.append("skill", skills);
    formData.append("quesAnsArray", JSON.stringify(QuestArr));
    // formData.append(
    //   "current_loc",
    //   destCompare[0]?.city === getCity ? getCity : ""
    // );

    // formData.append(
    //   "preferred_loc",
    //   selectedLocation === "preferred" ? preferredArr.join(",") : "Any Location"
    // );
    formData.append("current_loc", selectedCityId);
    formData.append(
      "preferred_loc",
      selectedLocation === "preferred" && preferredLocationIds.length > 0
        ? preferredLocationIds
        : selectedLocation === "anyLocation"
        ? 0
        : "Any Location"
    );
    setLoading(true);
    axios
      .post(`create-candidate`, formData, {
        headers: {
          authorization: getToken ? getToken : localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          setLoading(false);
          setSnackbarMessage("Candidate Added Successfully");
          setSnackbarOpen(true);
          setDrawerOpen(false);
          setEmail("");
          setFullname("");
          setMobile("");
          setCurrentAddress("");
          setGender("");
          setSelectedDate(null);
          setCountryCode("");
          setYears("");
          setMonths("");
          setCurrentEmployer("");
          setCurrentSalary("");
          setExpectedSalary("");
          setRadius("");
          setHighEducation("");
          setLinkedin("");
          setPortfolio("");
          setSkills([]);
          setSelectedLocation("");
          setPreferredArr([]);
          setSelectedFile(null);
          setFileError("");
          setFileName("");
          setUploadDate("");
          setpreferedLocation([]);
          setCurrentLocation([]);
          setEmailalreadyexists(false);
          setFile(null);
          setSelectedLocation("preferred");
          setCityLoc("");
          setprefcityLoc("");
        }
      })
      .catch((err) => {
        setLoading(false);
        handleSnackbarOpen(err.response.data.error);
        setEmailalreadyexists(true);
      });
  };

  // console.log("finalsummit",finalsummit)

  // console.log("!!!!!!!!!!!!!!!!!!!1",quesAnsArray)
  // console.log("addcandidaterquestionrequiredflag",questionsrequired)
  // const handleLinkedin = (event) => {
  //   const url = event.target.value;
  //   setLinkedin(url);
  //   if (!validateLinkedInURL(url)) {
  //     setLinkedinError('Invalid LinkedIn URL');
  //   } else {
  //     setLinkedinError('');
  //   }
  // };

  const handleCancelData = () => {
    setPreferredLocationIds([]);
    setSelectedCityId(null);
    setNoticePeriod("");
    setLastworkingday("");
    selectedDateAvailable("");
    setSelectedworkAuthorization("");
    setEmail("");
    setMonths("");
    setMobileError("");
    setCountryCodeError("");
    setFullname("");
    setMobile("");
    setCurrentAddress("");
    setGender("");
    setYears("");
    setCurrentEmployer("");
    setCurrentSalary("");
    setExpectedSalary("");
    setLinkedin("");
    setPortfolio("");
    setFile(null);
    setFileName("");
    setUploadDate("");
    setHighEducation("");
    setCountryCode("");
    setSelectedDate(null);
    setSkills([]);
    setPreferredLocations([]);
    setpreferedLocation([]);
    setCurrentLocation([]);
    setPreferredArr([]);
    setDrawerOpen(false);
    setSelectedLocation("preferred");
    setCityLoc("");
    setprefcityLoc("");
    setSkillsError("");
    setFileError("");
    setPreferredLocationError("");
    setEmailError("");
    setFullnameError("");
    setLinkedinError("");
    setPortfolioError("");
    setPortfolioErrorHelperText("");
    setEmailalreadyexists("");
  };

  const validateLinkedInURL = (url) => {
    if (url.trim() === "") {
      return true; // No validation needed if the field is empty
    }
    const pattern =
      /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm;
    return pattern.test(url);
  };

  const validateURL = (url) => {
    const pattern =
      /(https: \/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/;
    return pattern.test(url);
  };

  const MAX_PORTFOLIO_URL_LENGTH = 128;
  const validatePortfolioURL = (url) => {
    return validateURL(url) && url.length <= MAX_PORTFOLIO_URL_LENGTH;
  };

  const snackbarBackgroundColor =
    snackbarMessage === "Candidate Added successfully" ? "green" : "red";
  const handleSnackbarOpen = (message) => {
    console.log("Snackbar message:", message);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // useEffect(() => {
  //   axios
  //     .get(`all-skills`)
  //     .then((response) => {
  //       // console.log("******", response.data.message);
  //       setSkillsOption(response.data.message);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  useEffect(() => {
    axios
      .get(`countries`)
      .then((response) => {
        setCountries(response.data.message.map((e) => e["Dialing Code"]));
        setLocations(response.data.message.map((e) => e["state"]));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const fetchData = async () => {
    try {
      if (getCity && getCity.length > 0) {
        const response = await axios.post(
          process.env.REACT_APP_JOBEZEURL + "/state-city/list",
          {
            cityName: getCity,
          }
        );
        response.data.message && response.data.message.length > 0
          ? setCurrentLocation(response.data.message)
          : setCurrentLocation(currrentLocation);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };


  const preferedfetchData = async () => {
    try {
      if (getprefered && getprefered.length > 0) {
        const response = await axios.post(
          process.env.REACT_APP_JOBEZEURL + "/state-city/list",
          {
            cityName: getprefered,
          }
        );
        console.log("response", response);
        setpreferedLocation(response.data.message);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    const ondata = async () => {
      await fetchData();
    };
    ondata();
  }, [getCity]);

  useEffect(() => {
    const ondata = async () => {
      await preferedfetchData();
    };
    ondata();
  }, [getprefered]);

  const handleFullname = (event) => {
    const input = event.target.value;

    const regex = /^[a-zA-Z ]{0,61}$/;

    // Check if the input matches the regular expression
    if (regex.test(input) || input === "") {
      // Valid input, update the state
      setFullname(input);
    }
    if (input.length >= 61) {
      setFullnameError(
        "Please enter a valid with a maximum length of 60 characters"
      );
    } else {
      setFullnameError("");
    }
    // else: Invalid input, do nothing (or show an error message if you prefer)
  };

  const handleLinkedin = (event) => {
    setLinkedin(event.target.value);
  };

  const handlePortfolio = (event) => {
    setPortfolio(event.target.value);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleMobile = (e) => {
    const value = e.target.value;
    setMobileError("");
    // Validate that the input contains at most 10 digits
    if (/^\d{0,10}$/.test(value)) {
      setMobile(value);
    }
  };

  // const handleCountryCode = (e) => {
  //   const value = e.target.value;
  //   setMobile("");
  //   setMobileError("");
  //   setCountryCodeError("");
  //   // Validate that the input contains only digits and has a maximum length of 3
  //   if (/^\d{0,3}$/.test(value)) {
  //     setCountryCode(value);
  //     setIsCountryCodeSelected(value.length === 3); // Set flag to indicate if country code is selected
  //   }
  // };

  const handleCurrentAddress = (event) => {
    setCurrentAddress(event.target.value);
  };

  const handleYears = (event) => {
    setYears(event.target.value);
  };

  // const handleCurrentEmployer = (event) => {
  //   setCurrentEmployer(event.target.value);
  // };

  const handleCurrentSalary = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, "");
    const limitedValue = numericValue.substring(0, 10);
    setCurrentSalary(limitedValue);
  };

  const handleDateChange = (e) => {
    let date = "";
    if (e) {
      let m = e.$d.getMonth() + 1;
      m = m < 10 ? `0${m}` : m;
      let d = ("0" + e.$D).slice(-2);
      date = e.$d.getFullYear() + "-" + m + "-" + d;
      setSelectedDate(date);
    }
  };

  const handleDateAvailable = (e) => {
    let date = "";
    if (e) {
      let m = e.$d.getMonth() + 1;
      m = m < 10 ? `0${m}` : m;
      let d = ("0" + e.$D).slice(-2);
      date = e.$d.getFullYear() + "-" + m + "-" + d;
      selectedDateAvailable(date);
    }
  };

  const handleNoticePeriod = (event) => {
    const input = event.target.value;
    
    // Validate if input is numeric and up to 3 digits
    if (/^\d{0,2}$/.test(input)) {
      setNoticePeriod(input);
    }
  }

  const handlelastworkingday = (e) => {
    let date = "";
    if (e) {
      let m = e.$d.getMonth() + 1;
      m = m < 10 ? `0${m}` : m;
      let d = ("0" + e.$D).slice(-2);
      date = e.$d.getFullYear() + "-" + m + "-" + d;
      setLastworkingday(date);
    }
  };

  const handleHighEducation = (event) => {
    setHighEducation(event.target.value);
  };

  const handleworkAuthorization = (event) => {
    setSelectedworkAuthorization(event.target.value);
  };

  const handleMonths = (event) => {
    setMonths(event.target.value);
  };

  // const handleSkills = (_, newValues) => {
  //   console.log("Entered skills:", newValues);

  //   const labels = newValues.map((item) => (typeof item === "object" ? item.label : item));

  //   if(labels.length>0){
  //     const uniqueSkills = [...new Set([...skills.map(e=>e.toLowerCase()), ...labels.map(e=>e.toLowerCase())])];
  //     setSkills(uniqueSkills);
  //   }else{
  //     setSkills(newValues);
  //   }
  //   setSkillsError("");
  // };

  const handleSkills = (_, newValues) => {
    // console.log("Entered skills:", newValues);

    // Extract labels from objects if needed
    const labels = newValues.map((item) =>
      typeof item === "object" ? item.label : item
    );
    // console.log("skills", skills, typeof skills);

    // Use Set to remove duplicates
    if (labels.length > 0) {
      const uniqueSkills = [
        ...new Set([
          ...skills.map((e) => e.toLowerCase()),
          ...labels.map((e) => e.toLowerCase()),
        ]),
      ];
      setSkills(uniqueSkills);
    } else {
      setSkills(newValues);
    }
    setSkillsError("");
  };

  // Filter selected options from the available options
  const availableOptions = SkillsOption.filter(
    (option) => !skills.includes(option.label.toLowerCase())
  );

  const handleGender = (event) => {
    setGender(event.target.value);
  };

  const handleExpectedSalary = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, "");
    const limitedValue = numericValue.substring(0, 10);
    setExpectedSalary(limitedValue);
  };
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setFileError("");
    } else {
      setSelectedFile(null);
      setFileError("Please select a file.");
    }
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
    setUploadDate(new Date().toLocaleDateString());
  };

  const handleReplace = () => {
    setFile(null);
    setFileName("");
    setUploadDate("");
    setFileError("");
  };

  const handleSubmit = () => {
    let hasErrors = false;
    setDrawerOpen(true);

    if (!email) {
      setEmailError("Email is required.");
      hasErrors = true;
    } else {
      setEmailError("");
    }

    if (!fullname) {
      setFullnameError("Full Name is required.");
      hasErrors = true;
    } else {
      setFullnameError("");
    }

    // if (countryCode) {
    //   // Check if mobile number is required and has exactly 7 digits
    //   if (!mobile || !/^\d{7}$/.test(mobile)) {
    //     setMobileError('Mobile number is required and should contain exactly 7 digits.');
    //     return; // Stop submission if validation fails
    //   } else {
    //     setMobileError(''); // Clear mobile number error if validation passes
    //   }
    // }
    // if (countryCode && countryCode.length !== 3) {
    //   setCountryCodeError("Invalid country code.");
    //   hasErrors = true; // Stop submission if validation fails
    // } else {
    //   setCountryCodeError(""); // Clear country code error if validation passes

    //   if (countryCode) {
    //     // Check if mobile number is empty
    //     if (!mobile) {
    //       setMobileError("Mobile number is required.");
    //       hasErrors = true; // Stop submission if validation fails
    //     }

    //     // Check if mobile number has exactly 7 digits
    //     else if (!/^\d{10}$/.test(mobile)) {
    //       setMobileError("It should contain exactly 7 digits.");
    //       hasErrors = true; // Stop submission if validation fails
    //     } else {
    //       setMobileError("");
    //     }

    //     // Clear mobile number error if validation passes
    //   }
    // }

    if (mobile) {
      // Check if mobile number has exactly 10 digits
      if (!/^\d{10}$/.test(mobile)) {
        setMobileError("It should contain exactly 10 digits.");
        scrollToErrorField("mobile");
        hasErrors = true; // Stop submission if validation fails
      } else {
        setMobileError("");
      }
    }

    // console.log("himanshu", mobileError);

    if (linkedin && !validateLinkedInURL(linkedin)) {
      // console.log("Invalid LinkedIn URL 421");
      // The LinkedIn URL is valid, you can proceed with your form submission logic here.
      setLinkedinError("Invalid LinkedIn URL");
      return;
    } else {
      setLinkedinError("");
    }

    if (portfolio && !validatePortfolioURL(portfolio)) {
      setPortfolioError(true);
      setPortfolioErrorHelperText(
        "Invalid URL or URL length exceeds 128 characters"
      );
      return;
    }

    if (skills.length === 0) {
      setSkillsError("Skills are required.");
      hasErrors = true;
    } else if (skills.length < 5) {
      setSkillsError("Please select at least 5 skills.");
      hasErrors = true;
    } else {
      setSkillsError(""); // Clear the skills error if there are no errors
    }

    if (!selectedFile) {
      setFileError("Please select a file.");
      scrollToErrorField("file");
      hasErrors = true;
    } else {
      setFileError("");
      const allowedExtensions = ["pdf", "docx", "txt"];
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        setFileError("Only PDF, DOCX, and Text files are allowed.");
        hasErrors = true;
      }
    }

    if (selectedLocation === "preferred" && preferredArr.length === 0) {
      setPreferredLocationError("Preferred Location is required.");
      hasErrors = true;
    } else {
      setPreferredLocationError(""); // Clear the preferred location error if there are no errors
    }

    if (hasErrors) {
      return; // Do not submit the form if there are validation errors
    }

    if (questionsrequired) {
      setQuesOpenPopup(true);
      return;
    }

    const companyId = localStorage.getItem("company_id");
    // let destCompare = "";
    // if (currrentLocation) {
    //   destCompare = [...currrentLocation];
    //   const compareIndex = currrentLocation.findIndex(
    //     (e) => e.city === getCity
    //   );
    //   destCompare.splice(compareIndex, 1);
    //   destCompare.unshift(currrentLocation[compareIndex]);
    // }

    const formData = new FormData();

    formData.append("resume", file);
    // Append other fields to the FormData object
    formData.append("job_id", id);
    formData.append("email", email);
    formData.append("fullname", fullname);
    formData.append("mobile", mobile);
    formData.append("address", currentAddress);
    formData.append("gender", gender);
    if (selectedDate !== null) {
      formData.append("dob", selectedDate);
    }
    // formData.append("dob", selectedDate);
    formData.append("countryCode", "+1 " + countryCode);
    formData.append("year", years);
    formData.append("month", months);
    formData.append("current_employer", currentEmployer);
    formData.append("CTC", currentSalary);
    formData.append("Exp_CTC", expectedSalary);
    // Append other fields as needed
    // formData.append("radius", radius  ? radius : null);
    formData.append(
      "radius",
      selectedLocation === "anyLocation" ? null : radius ? radius : null
    );
    
    formData.append("highest_educ", highEducation ? highEducation : 1);
    formData.append("work_authorization", selectedworkAuthorization ? selectedworkAuthorization :1);
    formData.append("expected_joining_date", DateAvailable ? DateAvailable : null);
    formData.append("notice_period", NoticePeriod ? NoticePeriod : null);
    formData.append("last_working_day",lastworkingday ? lastworkingday : null);
    formData.append("linkedin", linkedin);
    formData.append("portfolio", portfolio);
    formData.append("skill", skills);
    formData.append("quesAnsArray", JSON.stringify([]));
    // formData.append(
    //   "current_loc",
    //   destCompare[0]?.city === getCity ? getCity : ""
    // );
    formData.append("current_loc", selectedCityId);
    //0 here considered as Any Location
    // formData.append("preferred_loc", selectedLocation === "preferred" && preferredLocationIds.length > 0 ?  preferredLocationIds: 0);
    formData.append(
      "preferred_loc",
      selectedLocation === "preferred" && preferredLocationIds.length > 0
        ? preferredLocationIds
        : selectedLocation === "anyLocation"
        ? 0
        : "Any Location"
    );
    // formData.append(
    //   "preferred_loc",
    //   selectedLocation === "preferred" ? preferredArr.join(",") : "Any Location"
    // );
    setLoading(true);
    axios
      .post(`create-candidate`, formData, {
        headers: {
          authorization: getToken ? getToken : localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          setLoading(false);
          setPreferredLocationIds([]);
          setSnackbarMessage("Candidate Added Successfully");
          setSnackbarOpen(true);
          setDrawerOpen(false);
          setEmail("");
          setFullname("");
          setMobile("");
          setCurrentAddress("");
          setGender("");
          setSelectedDate(null);
          setCountryCode("");
          setYears("");
          setMonths("");
          setCurrentEmployer("");
          setCurrentSalary("");
          setExpectedSalary("");
          setRadius("");
          setHighEducation("");
          setLinkedin("");
          setPortfolio("");
          setSkills([]);
          setSelectedLocation("");
          setPreferredArr([]);
          setSelectedFile(null);
          setFileError("");
          setFileName("");
          setUploadDate("");
          setpreferedLocation([]);
          setCurrentLocation([]);
          setEmailalreadyexists(false);
          setFile(null);
          setSelectedLocation("preferred");
          setCityLoc("");
          setprefcityLoc("");
          setHighEducationOptions([]);
          setNoticePeriod("");
          setLastworkingday("");
          selectedDateAvailable("");
          setSelectedworkAuthorization("");
          setSelectedCityId(null);
        }
      })
      .catch((err) => {
        setLoading(false);
        handleSnackbarOpen(err.response.data.error);
        setEmailalreadyexists(true);
      });
  };

  const scrollToErrorField = (fieldName) => {
    const errorFieldRef = errorFieldRefs[fieldName];
    if (errorFieldRef && errorFieldRef.current) {
      errorFieldRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const errorFieldRefs = {
    file: useRef(null),
    mobile: useRef(null),
    // Add similar refs for other fields...
  };

  const hanldePreferredInputChange = (value) => {
    // console.log(value);
    if (value.length > 2) setprefcityLoc(value);
  };

  const hanldeSkillInputChange = _debounce((value) => {
    // Call your API search function here (skillssearch)
    skillssearch(value);
  }, 415);

  const skillssearch = async (val) => {
    try {
      if (val) {
        const response = await axios.get(
          process.env.REACT_APP_BASEURL + `/all-skills?search=${val}`
        );
        console.log("response", response);
        setSkillsOption(response.data.message);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  useEffect(() => {
    // Fetch highest education options
    axios
      .get(process.env.REACT_APP_JOBEZEURL + `/highest-education`)
      .then((response) => {
        setHighEducationOptions(response.data.message);
      })
      .catch((error) => {
        console.error("Error fetching highest education options:", error);
      });

    // Fetch work authorization options
    axios
      .get(process.env.REACT_APP_JOBEZEURL + `/work-authorization`)
      .then((response) => {
        setWorkAuthorizationOptions(response.data.message);
      })
      .catch((error) => {
        console.error("Error fetching work authorization options:", error);
      });
  }, [isDrawerOpen]);




  const handleCitySelect = (event, value) => {
    if (value) {
      const selectedLocation = currrentLocation.find(loc => loc.city === value);
      if (selectedLocation) {
        setSelectedCityId(selectedLocation.city_id);
      }
    } else {
      setSelectedCityId(null); // Clear the selected city_id if value is cleared
    }
  }


  const handleLocationChange = (event, val) => {
    if (val.length <= 5) {
      setPreferredArr(val);
      setPreferredLocationError('');
      const selectedIds = val.map((location) => {
        const matchedLocation = preferredLocation.find(
          (item) => item.city === location
        );
        return matchedLocation ? matchedLocation.city_id : null;
      });
      setPreferredLocationIds(selectedIds.filter((id) => id !== null));
    } else {
      setPreferredLocationError('You can select up to 5 preferred locations.');
    }
  };

  
  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.7)", // Optional: Add a semi-transparent overlay
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <Drawer
            anchor="right"
            open={isDrawerOpen}
            // onClose={toggleDrawer(false)}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              p: { xs: 1, sm: 2, md: 2, lg: 2 },
            }}
          >
            <Box>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: { xs: 1, sm: 2, md: 2, lg: 2 },
                }}
              >
                <Typography variant="h5" component="div">
                  Add Candidate
                </Typography>
                <CloseIcon
                  onClick={handleCancelData}
                  sx={{ cursor: "pointer" }}
                />
              </Typography>
              <Divider />
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100vh",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  maxWidth={450}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: { xs: 1, sm: 2, md: 2, lg: 2 },
                    py: { xs: 1, sm: 2, md: 2, lg: 2 },
                    px: { xs: 1, sm: 2, md: 2, lg: 2 },
                  }}
                >
                  <Typography variant="body1" component="div">
                    <Typography
                      variant="h6"
                      gutterBottom
                      ref={errorFieldRefs.file}
                    >
                      Resume
                    </Typography>
                    {file === null ? (
                      <Typography
                        variant="body1"
                        align="center"
                        component="div"
                        sx={{
                          p: { xs: 1, sm: 2, md: 2, lg: 2 },
                          border: "1px solid #ccc",
                          borderStyle: "dashed",
                        }}
                        gutterBottom
                      >
                        <Typography
                          variant="body2"
                          display={"flex"}
                          className="fw-500"
                          sx={{ color: "rgb(0, 0, 0, 0.6)" }}
                          alignItems={"center"}
                          gap={1}
                          justifyContent={"center"}
                          mb={2}
                        >
                          <UploadFileIcon /> Drag and Drop file here Or
                        </Typography>
                        <Button
                          component="label"
                          variant="outlined"
                          size="small"
                        >
                          Upload file
                          <VisuallyHiddenInput
                            type="file"
                            onChange={handleFileChange}
                            accept=".pdf, .docx, text/plain"
                          />
                        </Button>
                        <Typography>
                          {FileError && (
                            <div style={{ color: "red" }}>{FileError}</div>
                          )}
                        </Typography>
                      </Typography>
                    ) : (
                      <Typography
                        variant="body1"
                        align="center"
                        component="div"
                        sx={{
                          p: { xs: 1, sm: 2, md: 2, lg: 2 },
                          border: "1px solid #ccc",
                          borderRadius: 1,
                        }}
                      >
                        <Typography
                          variant="body2"
                          display={"flex"}
                          className="fw-500"
                          sx={{ color: "rgb(0, 0, 0, 0.6)" }}
                          justifyContent={"space-between"}
                          gap={1}
                        >
                          <Typography
                            variant="body2"
                            width={"70%"}
                            overflow={"hidden"}
                            textOverflow={"ellipsis"}
                            whiteSpace={"nowrap"}
                            align="left"
                          >
                            <Typography
                              variant="body2"
                              display={"flex"}
                              alignItems={"center"}
                              gap={1}
                              gutterBottom
                            >
                              <FileOpenIcon />
                              <a
                                href="#resume"
                                title={fileName}
                                style={{
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  width: "90%",
                                  textDecoration: "none",
                                  color: "rgba(0, 108, 183, 1)",
                                  fontWeight: "bold",
                                }}
                                target="_blank"
                              >
                                {fileName}
                              </a>
                            </Typography>
                            <span className="fw-500 fs-14">
                              Uploaded on {uploadDate}
                            </span>
                          </Typography>
                          <Button
                            size="small"
                            component="label"
                            variant="outlined"
                            sx={{ m: "auto" }}
                            onClick={handleReplace}
                          >
                            Replace
                          </Button>
                        </Typography>
                        <span>
                          {FileError && (
                            <div style={{ color: "red" }}>{FileError}</div>
                          )}
                        </span>
                      </Typography>
                    )}
                  </Typography>
                  <TextField
                    id="fullname"
                    size="small"
                    fullWidth
                    label="Full Name"
                    variant="outlined"
                    value={fullname}
                    onFocus={(e) => setFullnameError("")}
                    onChange={handleFullname}
                    error={!!fullnameError}
                    helperText={fullnameError}
                  />
                  <Typography
                    variant="body2"
                    component="div"
                    sx={{
                      display: "flex",
                      gap: { xs: 1, sm: 2, md: 2, lg: 2 },
                    }}
                  >
                    <FormControl
                      size="small"
                      sx={{ width: { xs: 250, sm: 250, md: 250, lg: 250 } }}
                    >
                      <InputLabel size="small" id="demo-simple-select-label">
                        {" "}
                        Gender{" "}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={gender}
                        size="small"
                        label="Gender"
                        onChange={handleGender}
                      >
                        <MenuItem value="M">Male</MenuItem>
                        <MenuItem value="F">Female</MenuItem>
                        <MenuItem value="O">Prefer not to respond</MenuItem>
                      </Select>
                    </FormControl>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date of Birth"
                        maxDate={eighteenYearsAgo}
                        value={selectedDate ? dayjs(selectedDate) : null}
                        onChange={(date) => handleDateChange(date)}
                        slotProps={{
                          textField: {
                            readOnly: true,
                            size: "small",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Typography>
                  <TextField
                    id="email"
                    size="small"
                    type="email"
                    fullWidth
                    label="Email"
                    variant="outlined"
                    value={email}
                    onFocus={() => {
                      setEmailError("");
                      setEmailalreadyexists(false);
                    }}
                    onChange={handleEmail}
                    error={!!emailError || emailalreadyexists}
                    helperText={
                      emailError ||
                      (emailalreadyexists ? "Email already exists" : "")
                    }
                    onBlur={() => {
                      const emailRegex =
                        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

                      if (!emailRegex.test(email)) {
                        setEmailError("Invalid email format");
                      } else {
                        setEmailError(""); // Reset the error when the email is valid
                      }
                    }}
                    ref={errorFieldRefs.mobile}
                  />
                  <Box display={"flex"} gap={1}>
                    {/* <TextField
                      id="CountryCode"
                      size="small"
                      label="Country Code"
                      variant="outlined"
                      onChange={handleCountryCode}
                      type="tel"
                      value={countryCode}
                      placeholder="code"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PlusOneIcon fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                      helperText={countryCodeError}
                      error={!!countryCodeError}
                    /> */}
                    <TextField
                      id="Mobile"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PlusOneIcon fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      label="Mobile Number"
                      variant="outlined"
                      value={mobile}
                      // disabled={!isCountryCodeSelected}
                      onChange={handleMobile}
                      type="tel"
                      placeholder="Enter mobile number"
                      helperText={mobileError}
                      error={!!mobileError}
                    />
                  </Box>

                  <TextField
                    id="currentAddress"
                    label="Current Address"
                    multiline
                    rows={4}
                    value={currentAddress}
                    onChange={handleCurrentAddress}
                  />
                  <Typography variant="h6" component="div">
                    Experience
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: { xs: 1, sm: 2, md: 2, lg: 2 },
                    }}
                  >
                    <TextField
                      id="years"
                      size="small"
                      fullWidth
                      value={years}
                      label="Years"
                      variant="outlined"
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        // Check if the input is empty or a valid number within the range [0, 40]
                        if (
                          inputValue === "" ||
                          /^0$/.test(inputValue) ||
                          (/^[1-9]\d*$/.test(inputValue) &&
                            parseInt(inputValue) >= 1 &&
                            parseInt(inputValue) <= 40)
                        ) {
                          setYears(inputValue);
                          // Check if inputValue is equal to 40
                          if (parseInt(inputValue) === 40) {
                            setMonths(""); // Set months to an empty string
                          }
                        }
                        // If not, you can set an error message or take another action
                        else {
                          // You can set an error state or display an error message to the user.
                          // For this example, I'll set an error message in the state:
                        }
                      }}
                    />
                    <FormControl size="small" fullWidth>
                      <InputLabel size="small" id="demo-simple-select-label">
                        {" "}
                        Months{" "}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={months}
                        size="small"
                        label="Months"
                        onChange={handleMonths}
                        disabled={years === "40"}
                      >
                        {monthss.map((item) => {
                          return <MenuItem value={item}>{item}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Typography>
                  <TextField
                    id="currentEmployer"
                    size="small"
                    fullWidth
                    label="Current Employer"
                    variant="outlined"
                    value={currentEmployer}
                    // onChange={handleCurrentEmployer}
                    onChange={(e) => {
                      if (e.target.value.length <= 60) {
                        setCurrentEmployer(e.target.value);
                      }
                    }}
                  />
                  <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Current Salary
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      label="Current Salary"
                      placeholder="Current Salary"
                      size="small"
                      value={currentSalary}
                      onChange={handleCurrentSalary}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Expected Salary
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      label="Expected Salary"
                      placeholder="Expected Salary"
                      size="small"
                      value={expectedSalary}
                      onChange={handleExpectedSalary}
                    />
                  </FormControl>
                  <Autocomplete
                    fullWidth
                    // name={Math.random().toString(36).replace(/[^a-z]+/g, '')}
                    size="small"
                    onInputChange={(event, newInputValue) => {
                      // console.log("newInputValue", newInputValue);
                      if (newInputValue.length >= 3) {
                        setTimeout(() => {
                          setCityLoc(newInputValue);
                        }, 100);
                      } else {
                        setCityLoc("");
                        // Clear the options or perform any other desired action
                      }
                    }}
                    onChange={handleCitySelect}
                    id="controllable-states-demo"
                    options={
                      (currrentLocation &&
                        currrentLocation?.map((ele) => ele.city)) ||
                      []
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Current Location" />
                    )}
                  />
                  <Typography variant="h6" component="div">
                    Ready to relocate
                  </Typography>
                  <FormControl sx={{ mt: -2, mb: 2 }}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="preferred"
                      name="radio-buttons-group"
                      // onChange={(e) => setSelectedLocation(e.target.value)
                      // }
                      onChange={(e) => {
                        setSelectedLocation(e.target.value);
                        // if (e.target.value === "anyLocation") {
                        //   setPreferredLocationIds([]); // Clear preferredLocationIds
                        // }
                      }}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: { xs: 1, sm: 2, md: 3, lg: 3 },
                        }}
                      >
                        <FormControlLabel
                          sx={{ m: 0, p: 0 }}
                          value="preferred"
                          control={<Radio />}
                          label="Preferred Location"
                        />
                        <FormControlLabel
                          sx={{ m: 0, p: 0 }}
                          value="anyLocation"
                          control={<Radio />}
                          label="Any Location"
                        />
                      </Typography>
                    </RadioGroup>
                  </FormControl>
                  {selectedLocation === "preferred" ? (
                    <>
                      <Typography variant="h6" component="div">
                        Preferred Location
                      </Typography>
                      <Autocomplete
                        sx={{ mt: -1, mb: 3 }}
                        multiple
                        value={preferredArr}
                        // onChange={(e, val) => setPreferredArr(val)}
                        // onChange={(e, val) => {
                        //   if (val.length <= 5) {
                        //     setPreferredArr(val);
                        //     setPreferredLocationError("");
                        //   } else {
                        //     setPreferredLocationError(
                        //       "You can select up to 5 preferred locations."
                        //     );
                        //   }
                        // }}
                        onChange={handleLocationChange}
                        onInputChange={(event, newInputValue) => {
                          hanldePreferredInputChange(newInputValue);
                        }}
                        options={preferredLocation?.map((ele) => ele.city)}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant="filled"
                              {...getTagProps({ index })}
                              label={option}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            placeholder={
                              preferredLocations.length > 0
                                ? "Add another Locations"
                                : "Enter preferred locations"
                            }
                            helperText={preferredLocationError}
                            error={!!preferredLocationError}
                            onFocus={(e) => setPreferredLocationError("")}
                          />
                        )}
                      />
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{
                          display: "flex",
                          gap: { xs: 1, sm: 2, md: 2, lg: 2 },
                        }}
                      >
                        Radius (in miles)
                      </Typography>
                      <Typography variant="body2" sx={{ px: 1 }}>
                        <PrettoSlider
                          size="small"
                          defaultValue={20}
                          name="radius"
                          aria-label="Small"
                          valueLabelDisplay="auto"
                          value={radius}
                          onChange={(e) => setRadius(e.target.value)}
                        />
                      </Typography>
                    </>
                  ) : (
                    <></>
                  )}
                  <FormControl size="small" fullWidth>
                    <InputLabel size="small" id="demo-simple-select-label">
                      {" "}
                      Highest Education{" "}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={highEducation}
                      size="small"
                      label="Highest Education"
                      onChange={handleHighEducation}
                    >
                      {HighEducationOptions.map((options) => (
                        <MenuItem
                          className="fw-bold"
                          value={options.highest_education_id}
                        >
                          {options.highest_education}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl size="small" fullWidth>
                    <InputLabel size="small" id="demo-simple-select-label">
                      {" "}
                      Work Authorization{" "}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedworkAuthorization}
                      size="small"
                      label="Highest Education"
                      onChange={handleworkAuthorization}
                    >
                      {workAuthorizationOptions.map((options) => (
                        <MenuItem
                          className="fw-bold"
                          value={options.work_authorization_id}
                        >
                          {options.work_authorization}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    size="small"
                    fullWidth
                    label="Notice Period"
                    value={NoticePeriod}
                    onChange={handleNoticePeriod}
                    id="outlined-start-adornment"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">Days</InputAdornment>
                      ),
                    }}
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Last Working Day"
                      // maxDate={eighteenYearsAgo}
                      value={lastworkingday ? dayjs(lastworkingday) : null}
                      onChange={(date) => handlelastworkingday(date)}
                      slotProps={{
                        textField: {
                          readOnly: true,
                          size: "small",
                        },
                      }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Expected Joining Date"
                      // maxDate={eighteenYearsAgo}
                      minDate={dayjs()} 
                      value={DateAvailable ? dayjs(DateAvailable) : null}
                      onChange={(date) => handleDateAvailable(date)}
                      slotProps={{
                        textField: {
                          readOnly: true,
                          size: "small",
                        },
                      }}
                    />
                  </LocalizationProvider>
                  <TextField
                    id="linkedin"
                    size="small"
                    fullWidth
                    label="LinkedIn URL"
                    variant="outlined"
                    value={linkedin}
                    onChange={handleLinkedin}
                    error={Boolean(linkedinError)}
                    helperText={linkedinError}
                    onFocus={() => {
                      setLinkedinError("");
                    }}
                  />
                  <TextField
                    id="portfolio"
                    size="small"
                    fullWidth
                    label="Portfolio URL"
                    placeholder="Portfolio Link/Personal Website"
                    variant="outlined"
                    value={portfolio}
                    error={portfolioError}
                    helperText={profileErrorHelperText}
                    onChange={handlePortfolio}
                    onFocus={() => {
                      setPortfolioError("");
                      setPortfolioErrorHelperText("");
                    }}
                  />
                  <Typography variant="h6"> Skills </Typography>
                  <Autocomplete
                    sx={{ mt: -1, mb: 3 }}
                    multiple
                    freeSolo
                    options={availableOptions}
                    value={skills ? skills : []}
                    filterSelectedOptions
                    onChange={handleSkills}
                    onInputChange={(event, newInputValue) => {
                      hanldeSkillInputChange(newInputValue);
                    }}
                    renderTags={(value, getTagProps) =>
                      value?.map((option, index) => (
                        <Chip
                          variant="filled"
                          label={option}
                          {...getTagProps({ index })}
                          onDelete={() => {
                            const updatedSkills = skills?.filter(
                              (skill) => skill !== option
                            );
                            setSkills(updatedSkills);
                          }}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <div>
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          placeholder={
                            skills.length > 0
                              ? "Add another skills"
                              : "Enter Skills"
                          }
                          helperText={skillsError}
                          error={!!skillsError}
                          onFocus={(e) => setSkillsError("")}
                        />
                      </div>
                    )}
                  />

                  {/* <Autocomplete
        multiple
        id="tags-outlined"
        freeSolo
        options={[{ title: "The Shawshank Redemption", year: 1994 },
        { title: "The Godfather", year: 1972 },
        { title: "The Godfather: Part II", year: 1974 },
        { title: "The Dark Knight", year: 2008 },
        { title: "12 Angry Men", year: 1957 }]}
        getOptionLabel={(option) => option.title}
       
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="filterSelectedOptions"
            placeholder="Favorites"
          />
        )}
      /> */}
                </Box>

                <Typography
                  sx={{
                    p: { xs: 1, sm: 2, md: 2, lg: 2 },
                    position: "sticky",
                    bottom: 0,
                    backgroundColor: "#fff",
                    zIndex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    gap: { xs: 1, sm: 2, md: 2, lg: 2 },
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderTop: "1px solid rgba(0, 0, 0, 0.24)",
                  }}
                >
                  <Button
                    variant="text"
                    color="inherit"
                    onClick={handleCancelData}
                  >
                    {" "}
                    Cancel{" "}
                  </Button>

                  {/* {
                    questionsrequired === 1 ? 
                    <Button
                      variant="contained"
                      onClick={handleQuesPopupOpen}
                      disabled={fullnameError ? true : false}
                    >
                      Next
                    </Button>
                    :
                    <Button
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={fullnameError ? true : false}
                  >
                    {" "}
                    Add{" "}
                  </Button>
                  } */}
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={fullnameError ? true : false}
                  >
                    {questionsrequired === 1 ? "Next" : "Add"}
                  </Button>
                </Typography>
              </FormControl>

              <Questionpop
                quesOpenPopup={quesOpenPopup}
                quesAnsArray={quesAnsArray}
                setQuesAnsArray={setQuesAnsArray}
                handleQuesPopupClose={handleQuesPopupClose}
                handleQuessummit={handleQuessummit}
                textFields={textFields}
                setTextFields={setTextFields}
                radioValues={radioValues}
                setRadioValues={setRadioValues}
              />
            </Box>
          </Drawer>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={() => setSnackbarOpen(false)}
          >
            {/* <SnackbarContent
              message={snackbarMessage}
              style={{ backgroundColor: snackbarBackgroundColor }}
            />
          </Snackbar> */}
            <Alert
              onClose={() => setSnackbarOpen(false)}
              severity={
                snackbarMessage === "Candidate Added Successfully"
                  ? "success"
                  : "error"
              } // Adjust severity as needed
              sx={{ width: "100%" }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      )}
    </>
  );
};

export default AddCandidateDrawer;
