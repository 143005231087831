import { createSlice } from "@reduxjs/toolkit"
import { PURGE } from "redux-persist"

const initialState = {
    token:null,
    refreshToken: null
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        
        getTokenID: (state, action) => {
            return {
                ...state,
                token: action.payload,
            };
        },
        getRefreshToken: (state, action) => {
            return {
                ...state,
                refreshToken: action.payload
            }
        },
        logoutSuccess(state) {
            return {
                ...state,
                isVerified: undefined,
                token: null
            }
        },
        
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => initialState)
    },
})
export const { getTokenID, getRefreshToken, logoutSuccess } = userSlice.actions
export const selectUser = (state) => state.user
export const userReducer = userSlice.reducer
