import React, { useEffect, useState } from "react";
import "./header.css";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link, useLocation } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout"; // Logout icon
import { ListItemIcon } from "@mui/material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { getRefreshToken, getTokenID } from "../redux/modules/userSlice";
import { useDispatch } from "react-redux";

const pages = ["Jobs", "Find Talents"];
const settings = [
  { label: "Change Password", icon: <LockOpenIcon />, link: "/changepassword" },
  { label: "Logout", icon: <LogoutIcon />, link: "/login" },
];
const getRandomColor = () => {
  const getRandomHexDigit = () => {
    return Math.floor(Math.random() * 16).toString(16);
  };

  return `#${getRandomHexDigit()}${getRandomHexDigit()}${getRandomHexDigit()}${getRandomHexDigit()}${getRandomHexDigit()}${getRandomHexDigit()}`;
};

const Header = () => {
  const storedColor = localStorage.getItem("userColor");
  const initialRandomColor = storedColor || getRandomColor();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const dispatch = useDispatch();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const location = useLocation();
  const currentPath = location.pathname;

  const handleLogout = () => {
    localStorage.clear();
    dispatch(getTokenID(""));
    dispatch(getRefreshToken(""));
    const d = new Date();
    let expires = "expires=" + d.toUTCString();
    document.cookie = "_secure_ARJ_" + "=" + "" + ";" + expires + ";path=/";
    document.cookie = "_secure_ref" + "=" + "" + ";" + expires + ";path=/";
    document.cookie = "cookiesAccepted" + "=" + "" + ";" + expires + ";path=/";
    handleCloseUserMenu();
    window.location.href = "/login";
  };

  const [randomColor, setRandomColor] = useState(initialRandomColor);

  useEffect(() => {
    // If a new random color was generated, store it in localStorage
    if (!storedColor) {
      localStorage.setItem("userColor", randomColor);
    }
  }, [randomColor, storedColor]);

  return (
    <AppBar color="inherit" position="static" sx={{ marginBottom: "20px" }}>
      <Container maxWidth="xxl" sx={{ px: { xs: 1, sm: 2, md: 3, lg: 9 } }}>
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/jobOrders"
            sx={{
              mr: 6,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img src="/logo.png" className="" alt="Logo" width={250} />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="secondary"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page, index) => (
                <MenuItem
                  key={index}
                  component={Link}
                  to={page === "Jobs" ? "/jobOrders" : "/Find-talents"}
                  onClick={handleCloseNavMenu}
                >
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/jobOrders"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img src="/logo.png" className="" alt="Logo" width={250} />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page, index) => (
              <Button
                key={index}
                component={Link}
                to={page === "Jobs" ? "/jobOrders" : "/Find-talents"}
                onClick={handleCloseNavMenu}
                className={
                  (page === "Jobs" &&
                    (currentPath === "/jobOrders" ||
                      currentPath.startsWith("/jobOrders/"))) ||
                  (page === "Find Talents" &&
                    (currentPath === "/Find-talents" ||
                      currentPath.startsWith("/Find-talents/") ||
                      currentPath.startsWith("/searchCandidate")))
                    ? "active"
                    : ""
                }
                sx={{
                  my: 2,
                  px: 4,
                  color: "black",
                  display: "block",
                  textTransform: "capitalize",
                  fontSize: "16px",
                }}
                style={{
                  display: `${page === "Find Talents" ? "none" : "block"}`,
                }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <IconButton
              onClick={handleOpenUserMenu}
              sx={{ p: 0, cursor: "pointer", textTransform: "capitalize" }}
            >
              <Avatar
                alt={localStorage.getItem("username")}
                src="/static/images/avatar/2.jpg"
                sx={{ bgcolor: randomColor }}
              />
              <KeyboardArrowDownIcon sx={{ ml: 1 }} />
            </IconButton>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                sx={{
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  cursor: "default",
                  ":hover": { backgroundColor: "white" },
                }}
              >
                <Typography textAlign="start">
                  {localStorage.getItem("username")}
                  <div className="fs-14  ">
                    {localStorage.getItem("email_id")}
                  </div>
                </Typography>
              </MenuItem>
              {settings.map((setting) => (
                <Link
                  to={setting.link}
                  key={setting.label}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {/* <MenuItem onClick={handleCloseUserMenu}>
                                        <ListItemIcon>
                                            {setting.icon}
                                        </ListItemIcon>
                                        <Typography textAlign="center">{setting.label}</Typography>
                                    </MenuItem> */}
                  {setting.label === "Logout" ? (
                    <MenuItem onClick={handleLogout}>
                      <ListItemIcon>{setting.icon}</ListItemIcon>
                      <Typography textAlign="center">
                        {setting.label}
                      </Typography>
                    </MenuItem>
                  ) : (
                    <MenuItem onClick={handleCloseUserMenu}>
                      <ListItemIcon>{setting.icon}</ListItemIcon>
                      <Typography textAlign="center">
                        {setting.label}
                      </Typography>
                    </MenuItem>
                  )}
                </Link>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
