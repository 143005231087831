import axios from "axios"; import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTokenID } from "../redux/modules/userSlice";
//const baseURL = process.env.REACT_APP_BASEURL;
//axios.defaults.baseURL = `${baseURL}`;
const useAxiosInterceptor = () => {







const baseURL = process.env.REACT_APP_BASEURL;
axios.defaults.baseURL = `${baseURL}`;





  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (resp) => {
// console.log("resp",resp)
return resp
},
      async (error) => {
        if (
          error.response.status === 401 &&
          !error.config._retry &&
          error.config.url !== "refresh"
        ) {
          error.config._retry = true;
          try {
            // Request to refresh the token
            const response = await axios.get("refresh", { withCredentials: true });

            // Check if the refresh was successful
            if (
              response.status === 200 &&
              response.data &&
              response.data.message.token
            ) {
              // Get the new access token from the response
              const newToken = response.data.message.token;
              const d = new Date();
              d.setTime(d.getTime() + 30 * 24 * 60 * 60 *1000);
              let expires = "expires=" + d.toUTCString();
              document.cookie =
                "_secure_ARJ_" + "=" + newToken + ";" + expires + ";path=/";
              dispatch(getTokenID(newToken));
              // console.log("error",error.config)
              // console.log("mmmmmmmmmmmmmmmmmmmmmmm",error.config.headers.authorization)
              // Retry the original request
             // let obj = JSON.parse(error.config.headers);
              error.config.headers.authorization = newToken;

            //  error.config.data = JSON.stringify(obj);
              return axios(error.config);
            }
          } catch (err) {
            console.error("Error refreshing token", err);
          }
        }
        return Promise.reject(error);
      }
    );
    return () => {
      // Cleanup function to remove the interceptor when component unmounts
      axios.interceptors.response.eject(interceptor);
    };
  }, [dispatch, token]);
  // No need to return anything, the interceptor setup will be handled by useEffect
};
export default useAxiosInterceptor;