export const education_list = [
    "Applied Science BASC",                                                       
    "Associate of Arts - AA",
    "Associate of Arts and Sciences - AAS",
    "Associate of Science - AS",
    "Associate's Degree",
    "Bachelor of Applied Science - BASC",
    "Bachelor of Architecture - BArch",
    "Bachelor of Arts - BA",
    "Bachelor of Business Administration - BBA",
    "Bachelor of Commerce - BCom",
    "Bachelor of Education - BEd",
    "Bachelor of Engineering - BE",
    "Bachelor of Fine Arts - BFA",
    "Bachelor of Laws - LLB",
    "Bachelor of Medicine, Bachelor of Surgery - MBBS",
    "Bachelor of Pharmacy - BPharm",
    "Bachelor of Science - BS",
    "Bachelor of Technology - BTech",
    "Diploma of Education",
    "Doctor of Arts",
    "Doctor of Education - EdD",
    "Doctor of Law",
    "Doctor of Law ID",
    "Doctor of Law JD",
    "Doctor of Medicine - MD",
    "Doctor of Pharmacy - PharmD",
    "Doctor of Philosophy - PhD",
    "Doctor of Science",
    "Engineer's Degree",
    "Executive MBA",
    "First Professional Degree",
    "Foundation Degree",
    "GED",
    "Graduate Degree",
    "Graduate/Professional Certificate",
    "High School Diploma",
    "Higher National Diploma",
    "Licentiate Degree",
    "Master of Architecture - MArch",
    "Master of Arts - MA",
    "Master of Business Administration - MBA",
    "Master of Computer Applications - MCA",
    "Master of Divinity - MDiv",
    "Master of Education - MEd",
    "Master of Engineering - MEng",
    "Master of Fine Arts - MFA",
    "Master of Laws - LLM",
    "Master of Library & Information Science - MLIS",
    "Master of Philosophy - MPhil",
    "Master of Public Administration - MPA",
    "Master of Public Health - MPH",
    "Master of Science - MS",
    "Master of Social Work - MSW",
    "Master of Technology - MTech",
    "Middle School Diploma",
    "Post-baccalaureate Certificate",
    "Postgraduate Degree",
    "Undergraduate Certificate or Diploma",
    "Undergraduate Degree",
    "Others"
    
    ];
    