import React, { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
  Slider,
  Alert,
  Snackbar,
} from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { styled } from "@mui/material/styles";

import PlusOneIcon from "@mui/icons-material/PlusOne";
import dayjs from "dayjs";

const PrettoSlider = styled(Slider)({
  color: "#006CB7",
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 18,
    width: 18,
    backgroundColor: "#006CB7",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 26,
    height: 26,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#006CB7",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const monthss = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const CandidateEditableProfile = ({ candidateId }) => {
  const getToken = useSelector((state) => state.user.token);
  const [candidatedetails, setcandidatedetails] = useState({});

  const [isopen, setisopen] = useState(false);
  const { id } = useParams();

  const [fullname, setFullname] = useState("");
  const [fullnameError, setFullnameError] = useState("");
  const [gender, setGender] = useState("");
  const eighteenYearsAgo = dayjs().subtract(18, "year");
  const [selectedDate, setSelectedDate] = useState(null);
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [currentAddress, setCurrentAddress] = useState("");
  const [workAuthorizationOptions, setWorkAuthorizationOptions] = useState([]);
  const [years, setYears] = useState("");
  const [months, setMonths] = useState("");
  const [getCity, setCityLoc] = useState("");
  const [currrentLocation, setCurrentLocation] = useState(null);
  const [selectedCityId, setSelectedCityId] = useState(null);
  const [selectedworkAuthorization, setSelectedworkAuthorization] =
    useState("");
  const [currentEmployer, setCurrentEmployer] = useState("");
  const [currentSalary, setCurrentSalary] = useState("");
  const [expectedSalary, setExpectedSalary] = useState("");
  const [preferredArr, setPreferredArr] = useState([]);
  const [radius, setRadius] = useState(0);
  const [NoticePeriod, setNoticePeriod] = useState("");
  const [lastworkingday, setLastworkingday] = useState("");
  const [DateAvailable, selectedDateAvailable] = useState("");
  const [highEducation, setHighEducation] = useState("");
  const [HighEducationOptions, setHighEducationOptions] = useState([]);
  const [skills, setSkills] = useState([]);
  const [linkedin, setLinkedin] = useState("");
  const [linkedinError, setLinkedinError] = useState("");
  const [portfolio, setPortfolio] = useState("");
  const [portfolioError, setPortfolioError] = useState("");
  const [profileErrorHelperText, setPortfolioErrorHelperText] = useState("");
  const [Miscellaneous, setMiscellaneous] = useState("");
  const [currentlocationID, setCurrentlocationID] = useState("");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarServerity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [miscellaneousError, setMiscellaneousError] = useState("");
  const [tempArray, setTempArray] = useState({});
  const [isDataChanged, setIsDataChanged] = useState(false);

  const handlemiscellaneous = (event) => {
    setMiscellaneous(event.target.value);
    setTempArray({ ...tempArray, miscellaneous_notes: event.target.value });
  };
  const handlePortfolio = (event) => {
    setPortfolio(event.target.value);
    setTempArray({ ...tempArray, portfolio: event.target.value });
  };

  const handleFullname = (event) => {
    const input = event.target.value;

    const regex = /^[a-zA-Z ]{0,61}$/;

    // Check if the input matches the regular expression
    if (regex.test(input) || input === "") {
      // Valid input, update the state
      setFullname(input);
      setTempArray({ ...tempArray, name: input });
    }
    if (input.length >= 61) {
      setFullnameError(
        "Please enter a valid with a maximum length of 60 characters"
      );
    } else {
      setFullnameError("");
    }
    // else: Invalid input, do nothing (or show an error message if you prefer)
  };

  const handleLinkedin = (event) => {
    setLinkedin(event.target.value);
    setTempArray({ ...tempArray, linkedIn: event.target.value });
  };

  const handleGender = (event) => {
    setGender(event.target.value);
    setTempArray({ ...tempArray, gender: event.target.value });
  };

  const handleHighEducation = (event) => {
    console.log(event.target.value);
    setHighEducation(event.target.value);
    const obj = HighEducationOptions.filter(
      (item) => item.highest_education_id === event.target.value
    )[0];
    setTempArray({ ...tempArray, highest_educ: obj });
  };

  const handleDateChange = (e) => {
    let date = "";
    if (e) {
      let m = e.$d.getMonth() + 1;
      m = m < 10 ? `0${m}` : m;
      let d = ("0" + e.$D).slice(-2);
      date = e.$d.getFullYear() + "-" + m + "-" + d;
      setSelectedDate(date);
      setTempArray({ ...tempArray, dob: date });
    }
  };

  const handleMobile = (e) => {
    const value = e.target.value;
    setMobileError("");
    // Validate that the input contains at most 10 digits
    if (/^\d{0,10}$/.test(value)) {
      setMobile(value);
      setTempArray({ ...tempArray, mobile: value });
    }
  };

  const handleCurrentAddress = (event) => {
    setCurrentAddress(event.target.value);
    setTempArray({ ...tempArray, address: event.target.value });
  };

  const handleMonths = (event) => {
    setMonths(event.target.value);
    setTempArray({ ...tempArray, month: JSON.stringify(event.target.value) });
  };

  const handleCitySelect = (event, value, reason) => {
    if (value) {
      const selectedLocation = currrentLocation.find(
        (loc) => loc.city === value
      );
      if (selectedLocation) {
        setSelectedCityId(selectedLocation.city_id);
        // console.log("currrentLocation", currrentLocation);
        const cityobj = currrentLocation.filter(
          (item) => item.city_id === selectedLocation.city_id
        )[0];
        // console.log("value ", value, selectedLocation.city_id);
        // console.log("cityobj", cityobj);
        const new_obj = {
          label: cityobj.city,
          value: cityobj.city_id,
        };
        setTempArray({ ...tempArray, current_loc: [new_obj] });
      }
    } else {
      setSelectedCityId(null); // Clear the selected city_id if value is cleared
    }

    if (reason === "clear") {
      setCurrentlocationID(null);
      setSelectedCityId(null);
      setIsDataChanged(true);
    }
  };

  const handleworkAuthorization = (event) => {
    setSelectedworkAuthorization(event.target.value);
    const obj1 = workAuthorizationOptions.filter(
      (item) => item.work_authorization_id === event.target.value
    )[0];
    setTempArray({ ...tempArray, work_authorization: obj1 });
  };

  const handleCurrentSalary = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, "");
    const limitedValue = numericValue.substring(0, 10);
    setCurrentSalary(limitedValue);
    setTempArray({ ...tempArray, CTC: limitedValue });
  };

  const handleExpectedSalary = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, "");
    const limitedValue = numericValue.substring(0, 10);
    setExpectedSalary(limitedValue);
    setTempArray({ ...tempArray, Exp_CTC: limitedValue });
  };

  const handleNoticePeriod = (event) => {
    const input = event.target.value;

    // Validate if input is numeric and up to 3 digits
    if (/^\d{0,2}$/.test(input)) {
      setNoticePeriod(input);
      const numericInput = parseInt(input, 10); // Convert input to a number
      setTempArray({ ...tempArray, notice_period: numericInput });
    }
  };

  const handlelastworkingday = (e) => {
    let date = "";
    if (e) {
      let m = e.$d.getMonth() + 1;
      m = m < 10 ? `0${m}` : m;
      let d = ("0" + e.$D).slice(-2);
      date = e.$d.getFullYear() + "-" + m + "-" + d;
      setLastworkingday(date);
      setTempArray({ ...tempArray, last_working_day: date });
    }
  };

  const handleDateAvailable = (e) => {
    let date = "";
    if (e) {
      let m = e.$d.getMonth() + 1;
      m = m < 10 ? `0${m}` : m;
      let d = ("0" + e.$D).slice(-2);
      date = e.$d.getFullYear() + "-" + m + "-" + d;
      selectedDateAvailable(date);
      setTempArray({ ...tempArray, expected_joining_date: date });
    }
  };

  useEffect(() => {
    const ondata = async () => {
      await fetchData();
    };
    ondata();
  }, [getCity]);

  // useEffect to compare data whenever originalData or tempArray changes
  useEffect(() => {
    // Check if there are any differences between originalData and tempArray

    const dataChanged =
      JSON.stringify(candidatedetails) !== JSON.stringify(tempArray);

    setIsDataChanged(dataChanged);
  }, [candidatedetails, tempArray]);

  const fetchData = async () => {
    try {
      if (getCity && getCity.length > 0) {
        const response = await axios.post(
          process.env.REACT_APP_JOBEZEURL + "/state-city/list",
          {
            cityName: getCity,
          }
        );
        response.data.message && response.data.message.length > 0
          ? setCurrentLocation(response.data.message)
          : setCurrentLocation(currrentLocation);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const validateLinkedInURL = (url) => {
    if (url.trim() === "") {
      return true; // No validation needed if the field is empty
    }
    const pattern =
      /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/gm;
    return pattern.test(url);
  };

  const validateURL = (url) => {
    const pattern =
      /(https: \/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/;
    return pattern.test(url);
  };

  const MAX_PORTFOLIO_URL_LENGTH = 128;
  const validatePortfolioURL = (url) => {
    return validateURL(url) && url.length <= MAX_PORTFOLIO_URL_LENGTH;
  };

  const pref = preferredArr.map((item) => item.value);
  // console.log("pref", pref);

  const scrollToErrorField = (fieldName) => {
    const errorFieldRef = errorFieldRefs[fieldName];
    if (errorFieldRef && errorFieldRef.current) {
      errorFieldRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const errorFieldRefs = {
    fullname: useRef(null),
    mobile: useRef(null),
    // Add similar refs for other fields...
  };

  
  useEffect(() => {
    // const candidateId = selectedRowData?.id;
    axios
      .post(
        `candidate/details/${candidateId}?isEditableProfile=true`,
        {
          jobId: id,
        },
        {
          headers: {
            authorization: getToken ? getToken : localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {

        // setValue(newvalues);

        const original_data = response.data.message;
        delete original_data["activities"];
        delete original_data["additional_info"];
        delete original_data["cover_letter"];
        delete original_data["profile"];
        delete original_data["profile_path"];
        delete original_data["relocate"];
        delete original_data["resume"];
        delete original_data["resume_path"];
        delete original_data["resume_date"];

        setcandidatedetails(original_data);
        setTempArray(original_data);
        setFullname(response?.data?.message?.name);
        setGender(response?.data?.message?.gender);
        setSelectedDate(response?.data?.message?.dob);
        setEmail(response?.data?.message?.email);
        setMobile(response?.data?.message?.mobile);
        setCurrentAddress(response?.data?.message?.address);
        setYears(response?.data?.message?.year);
        setMonths(response?.data?.message?.month);
    
        setSelectedworkAuthorization(
          response?.data?.message?.work_authorization?.work_authorization_id
        );
        setCurrentEmployer(response?.data?.message?.current_employer);
        setCurrentSalary(response?.data?.message?.CTC);
        setExpectedSalary(response?.data?.message?.Exp_CTC);
        setCityLoc(response?.data?.message?.current_loc[0]?.label);
        setCurrentlocationID(response?.data?.message?.current_loc[0]?.value);
        setPreferredArr(response?.data?.message?.preferred_loc);
        // setRadius(response?.data?.message?.radius);
        setNoticePeriod(response?.data.message?.notice_period);
        setLastworkingday(response?.data.message?.last_working_day);
        setHighEducation(
          response?.data?.message?.highest_educ?.highest_education_id
        );
        // setSkills(response.data.message.skill);
        setLinkedin(response?.data?.message?.linkedIn);
        setPortfolio(response?.data?.message?.portfolio);
        selectedDateAvailable(response?.data?.message?.expected_joining_date);
        setMiscellaneous(response?.data.message?.miscellaneous_notes);

        // console.log("noticeeeeeeeeeeeeeeeeeeeeeeeeeee", response.data);
        // setOpennew((prevOpen) => {
        //   // Set to false if the drawer is open, otherwise toggle the value
        //   return isOpen ? false : !prevOpen;
        // });
      })
      .catch((err) => {});
  }, [isopen]);

  useEffect(() => {
    // Fetch highest education options
    axios
      .get(process.env.REACT_APP_JOBEZEURL + `/highest-education`, {
        headers: {
          authorization: getToken ? getToken : localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setHighEducationOptions(response.data.message);
      })
      .catch((error) => {
        console.error("Error fetching highest education options:", error);
      });

    // Fetch work authorization options
    axios
      .get(process.env.REACT_APP_JOBEZEURL + `/work-authorization`, {
        headers: {
          authorization: getToken ? getToken : localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setWorkAuthorizationOptions(response.data.message);
      })
      .catch((error) => {
        console.error("Error fetching work authorization options:", error);
      });
  }, [isopen]);


 
  const handleSubmit = () => {
    let hasErrors = false;
    // setDrawerOpen(true);

    if (!fullname) {
      setFullnameError("Full Name is required.");
      scrollToErrorField("fullname");
      hasErrors = true;
    } else {
      setFullnameError("");
    }

    if (mobile) {
      // Check if mobile number has exactly 10 digits
      if (!/^\d{10}$/.test(mobile)) {
        setMobileError("It should contain exactly 10 digits.");
        scrollToErrorField("mobile");
        // scrollToErrorField("mobile");
        hasErrors = true; // Stop submission if validation fails
      } else {
        setMobileError("");
      }
    }

    // console.log("himanshu", mobileError);

    if (linkedin && !validateLinkedInURL(linkedin)) {
      // console.log("Invalid LinkedIn URL 421");
      // The LinkedIn URL is valid, you can proceed with your form submission logic here.
      setLinkedinError("Invalid LinkedIn URL");
      return;
    } else {
      setLinkedinError("");
    }

    if (portfolio && !validatePortfolioURL(portfolio)) {
      setPortfolioError(true);
      setPortfolioErrorHelperText(
        "Invalid URL or URL length exceeds 128 characters"
      );
      return;
    }

    if (Miscellaneous?.length > 1000) {
      setMiscellaneousError("Maximum 1000 characters required.");
      hasErrors = true;
    } else {
      setMiscellaneousError("");
    }

    if (hasErrors) {
      return; // Do not submit the form if there are validation errors
    }

    // const companyId = localStorage.getItem("company_id");
    // setLoading(true);
    axios
      .post(
        `/duplicate-profile/${candidateId}`,
        {
          fullname: fullname,
          mobile: mobile,
          address: currentAddress,
          gender: gender,
          current_employer: currentEmployer,
          dob: selectedDate,
          CTC: currentSalary,
          Exp_CTC: expectedSalary,
          year: years,
          month: months,
          current_loc: selectedCityId ? selectedCityId : currentlocationID ? currentlocationID : null,
          highest_educ: highEducation ? highEducation : 1,
          linkedin: linkedin,
          portfolio: portfolio,
          work_authorization: selectedworkAuthorization
            ? selectedworkAuthorization
            : 1,
          expected_joining_date: DateAvailable ? DateAvailable : null,
          notice_period: NoticePeriod || NoticePeriod === 0 ? NoticePeriod : null,
          miscellaneous_notes: Miscellaneous,
          last_working_day: lastworkingday ? lastworkingday : null,
        },
        {
          headers: {
            authorization: getToken ? getToken : localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          // setLoading(false);
          // setPreferredLocationIds([]);
          setSnackbarMessage(response.data.message);
          setSnackbarOpen(true);
          setSnackbarServerity("success");
          setisopen(true);
        }
      })
      .catch((err) => {
        console.log("err", err.message);
        // setLoading(false);
        setSnackbarOpen(true);
        setSnackbarMessage(err.response.data.error);
        setSnackbarServerity("error");
        // setEmailalreadyexists(true);
      });
  };

  return (
    <div>
      <Box sx={{ mb: 5 }}>
        <h4>Basic Details</h4>
        <Grid container spacing={2}>
          <Grid item xs={4} sx={{ mt: 1 }}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
            >
              Name
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              id="fullname"
              size="small"
              fullWidth
              label="Full Name"
              variant="outlined"
              value={fullname}
              onFocus={(e) => setFullnameError("")}
              onChange={handleFullname}
              error={!!fullnameError}
              helperText={fullnameError}
              ref={errorFieldRefs.fullname}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
              sx={{ mt: 1 }}
            >
              Gender
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <FormControl size="small" fullWidth>
              <InputLabel size="small" id="demo-simple-select-label">
                {" "}
                Gender{" "}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={gender}
                size="small"
                label="Gender"
                onChange={handleGender}
              >
                <MenuItem value="M">Male</MenuItem>
                <MenuItem value="F">Female</MenuItem>
                <MenuItem value="O">Prefer not to respond</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
              sx={{ mt: 1 }}
            >
              Date of Birth
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date of Birth"
                maxDate={eighteenYearsAgo}
                value={selectedDate ? dayjs(selectedDate) : null}
                onChange={(date) => handleDateChange(date)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    readOnly: true,
                    size: "small",
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
              sx={{ mt: 1 }}
            >
              Email
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              id="email"
              size="small"
              type="email"
              fullWidth
              label="Email"
              disabled
              variant="outlined"
              value={email}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
              sx={{ mt: 1 }}
            >
              Mobile
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              id="Mobile"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PlusOneIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              fullWidth
              label="Mobile Number"
              variant="outlined"
              value={mobile}
              onChange={handleMobile}
              type="tel"
              placeholder="Enter mobile number"
              helperText={mobileError}
              error={!!mobileError}
              ref={errorFieldRefs.mobile}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
              sx={{ mt: 1 }}
            >
              Current Address
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              id="currentAddress"
              label="Current Address"
              multiline
              size="small"
              fullWidth
              // rows={4}
              value={currentAddress}
              onChange={handleCurrentAddress}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
              sx={{ mt: 1 }}
            >
              Work Authorization
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <FormControl size="small" fullWidth>
              <InputLabel size="small" id="demo-simple-select-label">
                {" "}
                Work Authorization{" "}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={
                  selectedworkAuthorization == 1
                    ? null
                    : selectedworkAuthorization
                }
                size="small"
                label="Highest Education"
                onChange={handleworkAuthorization}
              >
                {workAuthorizationOptions.map((options) => (
                  <MenuItem
                    className="fw-bold"
                    value={options.work_authorization_id}
                  >
                    {options.work_authorization}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mb: 5 }}>
        <h4>Professional Details</h4>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
              sx={{ mt: 1 }}
            >
              Total Experience
            </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{ display: "flex", alignItems: "center", gap: 2 }}
          >
            <TextField
              id="years"
              size="small"
              fullWidth
              value={years}
              label="Years"
              variant="outlined"
              onChange={(e) => {
                const inputValue = e.target.value;
                // Check if the input is empty or a valid number within the range [0, 40]
                if (
                  inputValue === "" ||
                  /^0$/.test(inputValue) ||
                  (/^[1-9]\d*$/.test(inputValue) &&
                    parseInt(inputValue) >= 1 &&
                    parseInt(inputValue) <= 40)
                ) {
                  setYears(inputValue);
                  setTempArray({ ...tempArray, year: inputValue });
                  // Check if inputValue is equal to 40
                  if (parseInt(inputValue) === 40) {
                    setMonths(""); // Set months to an empty string
                  }
                }
                // If not, you can set an error message or take another action
                else {
                  // You can set an error state or display an error message to the user.
                  // For this example, I'll set an error message in the state:
                }
              }}
            />
            <FormControl size="small" fullWidth>
              <InputLabel size="small" id="demo-simple-select-label">
                {" "}
                Months{" "}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={months}
                size="small"
                label="Months"
                onChange={handleMonths}
                disabled={years === "40"}
              >
                {monthss.map((item) => {
                  return <MenuItem value={item}>{item}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
              sx={{ mt: 1 }}
            >
              Current Employer
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              id="currentEmployer"
              size="small"
              fullWidth
              label="Current Employer"
              variant="outlined"
              value={currentEmployer}
              onChange={(e) => {
                if (e.target.value.length <= 60) {
                  setCurrentEmployer(e.target.value);
                  setTempArray({
                    ...tempArray,
                    current_employer: e.target.value,
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
              sx={{ mt: 1 }}
            >
              Current Salary
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth>
              <InputLabel htmlFor="outlined-adornment-amount">
                Current Salary
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Current Salary"
                placeholder="Current Salary"
                size="small"
                value={currentSalary}
                onChange={handleCurrentSalary}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
              sx={{ mt: 1 }}
            >
              Expected Salary
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth>
              <InputLabel htmlFor="outlined-adornment-amount">
                Expected Salary
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Expected Salary"
                placeholder="Expected Salary"
                size="small"
                value={expectedSalary}
                onChange={handleExpectedSalary}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
              sx={{ mt: 1 }}
            >
              Current Location
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              fullWidth
              value={getCity}
              // name={Math.random().toString(36).replace(/[^a-z]+/g, '')}
              size="small"
              onInputChange={(event, newInputValue) => {
                // console.log("newInputValue", newInputValue);
                if (newInputValue.length >= 3) {
                  setTimeout(() => {
                    setCityLoc(newInputValue);
                  }, 100);
                } else {
                  setCityLoc("");
                  // Clear the options or perform any other desired action
                }
              }}
              onChange={handleCitySelect}
              id="controllable-states-demo"
              options={
                (currrentLocation &&
                  currrentLocation?.map((ele) => ele.city)) ||
                []
              }
              renderInput={(params) => (
                <TextField {...params} label="Current Location" />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
              sx={{ mt: 2 }}
            >
              Preferred Location
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              multiple
              value={preferredArr}
              disabled
              // onChange={handleLocationChange}
              // onInputChange={(event, newInputValue) => {
              //   hanldePreferredInputChange(newInputValue);
              // }}
              options={preferredArr}
              renderTags={(value, getTagProps) =>
                value?.map((option, index) => (
                  <Chip
                    variant="filled"
                    {...getTagProps({ index })}
                    label={option.label}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  placeholder={
                    preferredArr?.length > 0 ? "" : "Preferred Location"
                  }
                  fullWidth
                  // helperText={preferredLocationError}
                  // error={!!preferredLocationError}
                  // onFocus={(e) => setPreferredLocationError("")}
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
            >
              Radius
            </Typography>
          </Grid> */}
          {/* <Grid item xs={8}>
            <PrettoSlider
              size="small"
              defaultValue={40}
              name="radius"
              aria-label="Small"
              valueLabelDisplay="auto"
              value={radius}
              disabled
              onChange={(e) => setRadius(e.target.value)}
            />
          </Grid> */}
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
              sx={{ mt: 1 }}
            >
              Notice Period
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              size="small"
              fullWidth
              label="Notice Period"
              value={NoticePeriod}
              onChange={handleNoticePeriod}
              id="outlined-start-adornment"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">Days</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
              sx={{ mt: 1 }}
            >
              Last working Day
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Last Working Day"
                value={lastworkingday ? dayjs(lastworkingday) : null}
                onChange={(date) => handlelastworkingday(date)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    readOnly: true,
                    size: "small",
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
              sx={{ mt: 1 }}
            >
              Expected Joining Date
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Expected Joining Date"
                // maxDate={eighteenYearsAgo}
                minDate={dayjs()}
                value={DateAvailable ? dayjs(DateAvailable) : null}
                onChange={(date) => handleDateAvailable(date)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    readOnly: true,
                    size: "small",
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mb: 5 }}>
        <h4>Other Details</h4>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
              sx={{ mt: 1 }}
            >
              Highest Education
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <FormControl size="small" fullWidth>
              <InputLabel size="small" id="demo-simple-select-label">
                {" "}
                Highest Education{" "}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={highEducation == 1 ? null : highEducation}
                size="small"
                label="Highest Education"
                onChange={handleHighEducation}
              >
                {HighEducationOptions.map((options) => (
                  <MenuItem
                    className="fw-bold"
                    value={options.highest_education_id}
                  >
                    {options.highest_education}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
              sx={{ mt: 2 }}
            >
              Skills
            </Typography>
          </Grid> */}
          {/* <Grid item xs={8} display={"flex"} gap={1} flexWrap={"wrap"}>
            <Autocomplete
              multiple
              freeSolo
              disabled
              fullWidth
              // options={availableOptions}
              value={skills ? skills : []}
              filterSelectedOptions
              // onChange={handleSkills}
              // onInputChange={(event, newInputValue) => {
              //   hanldeSkillInputChange(newInputValue);
              // }}
              renderTags={(value, getTagProps) =>
                value?.map((option, index) => (
                  <Chip
                    variant="filled"
                    label={option.label}
                    {...getTagProps({ index })}
                    // onDelete={() => {
                    //   const updatedSkills = skills?.filter(
                    //     (skill) => skill !== option
                    //   );
                    //   setSkills(updatedSkills);
                    // }}
                  />
                ))
              }
              renderInput={(params) => (
                <div>
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    placeholder={skills.length > 0 ? " " : "Enter Skills"}
                    // helperText={skillsError}
                    // error={!!skillsError}
                    // onFocus={(e) => setSkillsError("")}
                  />
                </div>
              )}
            />
          </Grid> */}
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
              sx={{ mt: 1 }}
            >
              Linkedin
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              id="linkedin"
              size="small"
              fullWidth
              label="LinkedIn URL"
              variant="outlined"
              value={linkedin}
              onChange={handleLinkedin}
              error={Boolean(linkedinError)}
              helperText={linkedinError}
              onFocus={() => {
                setLinkedinError("");
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
              sx={{ mt: 1 }}
            >
              Portfolio Link
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              id="portfolio"
              size="small"
              fullWidth
              label="Portfolio URL"
              placeholder="Portfolio Link/Personal Website"
              variant="outlined"
              value={portfolio}
              error={portfolioError}
              helperText={profileErrorHelperText}
              onChange={handlePortfolio}
              onFocus={() => {
                setPortfolioError("");
                setPortfolioErrorHelperText("");
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
              sx={{ mt: 3 }}
            >
              Miscellaneous Notes
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              id="Miscellaneous"
              size="small"
              fullWidth
              label="Miscellaneous Notes"
              placeholder="Miscellaneous Notes"
              variant="outlined"
              rows={Miscellaneous?.length > 85 ? 15 : 3}
              multiline
              value={Miscellaneous}
              onChange={handlemiscellaneous}
              error={!!miscellaneousError}
              helperText={miscellaneousError}
              onFocus={() => {
                setMiscellaneousError("");
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"flex-end"}
            mt={2}
          >
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={!isDataChanged}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
      {/* <Box sx={{ mb: 5 }} id="coverletterscrol">
        {candidatedetails?.cover_letter !== null &&
          candidatedetails?.cover_letter?.trim() !== "" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "center",
              }}
            >
              <Box>
                <Typography variant="h6" className="fw-500 fs-20">
                  Cover Letter
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  onClick={handleDownloadPDF}
                  startIcon={<FileDownloadIcon />}
                >
                  Download Cover letter
                </Button>
              </Box>
            </Box>
          )}
        {candidatedetails?.cover_letter !== null &&
          candidatedetails?.cover_letter?.trim() !== "" && (
            <Box
              // ref={boxRef}
              sx={{
                minHeight: boxHeight,
                border: "1px solid rgba(0, 0, 0, 0.1)",
                borderRadius: "4px",
                mt: 1,
                overflow: "scroll",
              }}
            >
              <div
                ref={boxRef}
                style={{ marginTop: "10px", marginLeft: "10px" }}
                // dangerouslySetInnerHTML={{
                //   __html: candidatedetails.cover_letter,
                // }}
                className="cover-letter-content"
                dangerouslySetInnerHTML={{
                  __html: candidatedetails.cover_letter,
                }}
              />
            </Box>
          )}
      </Box> */}

      {/* {candidatedetails.resume_path === "" ? (
        <div id="resumescrol">
          <Typography variant="body1" className="fw-500">
            No resume found
          </Typography>
        </div>
      ) : (
        <div id="resumescrol">
          <Box sx={{ mb: 5 }}>
            <Typography
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              pb={2}
            >
              <Typography variant="h6" className="fw-500 fs-20">
                Resume
              </Typography>
              {fileExt === "txt" && (
                <Button
                  variant="contained"
                  onClick={handleDownloadFile}
                  startIcon={<FileDownloadIcon />}
                  sx={{ textTransform: "none" }}
                >
                  Download Resume
                </Button>
              )}
            </Typography>
            {fileExt === "pdf" || fileExt === "txt" || fileExt === "doc" ? (
              <iframe
                src={candidatedetails.resume_path}
                referrerPolicy="no-referrer-when-downgrade"
                seamless="seamless"
                width="100%"
                height="1000px"
                title={candidatedetails.resume}
                frameBorder="0"
                className="border"
              ></iframe>
            ) : (
              <Box sx={{ height: "65vh" }}>
                {docs === null ? (
                  <Typography
                    variant="body1"
                    textAlign={"center"}
                    className="fw-500"
                  >
                    No Resume
                  </Typography>
                ) : (
                  <DocViewer
                    documents={docs}
                    pluginRenderers={DocViewerRenderers}
                    style={{ height: "100%" }}
                    config={{
                      header: {
                        disableHeader: true,
                        disableFileName: true,
                      },
                    }}
                    theme={{
                      disableThemeScrollbar: true,
                    }}
                  />
                )}
              </Box>
            )}
            {candidatedetails.additional_info && (
              <>
                <Typography variant="h6" className="fw-500 fs-20">
                  Additional Info
                </Typography>
                <Box
                  sx={{
                    minHeight: additionalboxheight,
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    mt: 1,
                    overflow: "scroll",
                  }}
                >
                  <div
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                    ref={addRef}
                    dangerouslySetInnerHTML={{
                      __html: candidatedetails.additional_info.replace(
                        /\n/g,
                        "<br>"
                      ),
                    }}
                  />
                </Box>
              </>
            )}
          </Box>
        </div>
      )} */}

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        {/* <SnackbarContent
              message={snackbarMessage}
              style={{ backgroundColor: snackbarBackgroundColor }}
            />
          </Snackbar> */}
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity} // Adjust severity as needed
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CandidateEditableProfile;
