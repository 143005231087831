// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-structure {
  display: flex;
  padding: 16px 64px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20);;
}
.logoWithTabs{
    display: flex;
    gap: 60px;
    align-items: center;
}

.border{
    border: 1px solid black;
}
`, "",{"version":3,"sources":["webpack://./src/components/header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,8BAA8B;EAC9B,mBAAmB;EACnB,4CAA4C;EAC5C,gBAAgB;EAChB,+CAA+C;AACjD;AACA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".header-structure {\n  display: flex;\n  padding: 16px 64px;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.12);\n  background: #FFF;\n  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20);;\n}\n.logoWithTabs{\n    display: flex;\n    gap: 60px;\n    align-items: center;\n}\n\n.border{\n    border: 1px solid black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
