import { Box, Grid, IconButton, Typography } from "@mui/material";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import React from "react";

const CandidateActivities = (candidatedetails) => {
  const activities = candidatedetails.candidatedetails.activities;

  const currentDate = new Date().toLocaleDateString();
  // console.log("aak ka dukha",currentDate)

  // Calculate the date for yesterday
  const yesterday = new Date();
  yesterday?.setDate(yesterday?.getDate() - 1);
  const yesterdayDate = new Date(yesterday)?.toLocaleDateString();
  // console.log("yesterdayDate", yesterdayDate);

  // Split the tasks into three arrays
  const todayTasks = [];
  const yesterdayTasks = [];
  const remainingTasks = [];

  activities?.forEach((task) => {
    // console.log("ssssssssssssss",task.date_created,currentDate)
    const getdate = new Date(task.date_created).toLocaleDateString();
    if (getdate === currentDate) {
      todayTasks.push(task);
    } else if (getdate === yesterdayDate) {
      yesterdayTasks.push(task);
    } else {
      remainingTasks.push(task);
    }
  });

  const date = new Date(remainingTasks[0]?.date_created).toLocaleDateString();
  // console.log('Today\'s Schedule:', todayTasks);
  // console.log('Yesterday\'s Schedule:', yesterdayTasks);
  // console.log('Remaining Tasks:', remainingTasks);
  // console.log("date",date,remainingTasks?.date_created)
  return (
    <div>
      {todayTasks.length > 0 ? (
        <Box sx={{ my: 2 }}>
          <Typography variant="body2" color={"text.secondary"} sx={{ mb: 2 }}>
            Today
          </Typography>
          {todayTasks &&
            todayTasks.map((item) => {
              return (
                <Grid
                  sx={{ mb: 2 }}
                  container
                  alignItems={"center"}
                  spacing={2}
                >
                  <Grid item xs={1}>
                    <IconButton
                      sx={{ backgroundColor: "#E0E0E0", pointerEvents: "none" }}
                    >
                      <AccessTimeOutlinedIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={11}>
                    <Typography component={"div"}>
                      <Typography variant="body1" gutterBottom>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.notes.replace(/\n/g, "<br />"),
                          }}
                        />
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
        </Box>
      ) : (
        ""
      )}
      {yesterdayTasks.length > 0 ? (
        <Box sx={{ my: 2 }}>
          <Typography variant="body2" color={"text.secondary"} sx={{ mb: 2 }}>
            Yesterday
          </Typography>
          {yesterdayTasks &&
            yesterdayTasks.map((item) => {
              return (
                <Grid
                  sx={{ mb: 2 }}
                  container
                  alignItems={"center"}
                  spacing={2}
                >
                  <Grid item xs={1}>
                    <IconButton
                      sx={{ backgroundColor: "#E0E0E0", pointerEvents: "none" }}
                    >
                      <AccessTimeOutlinedIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={11}>
                    <Typography component={"div"}>
                      <Typography variant="body1" gutterBottom>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.notes.replace(/\n/g, "<br />"),
                          }}
                        />
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
        </Box>
      ) : (
        ""
      )}
      {remainingTasks.length > 0 ? (
        <Box sx={{ my: 2 }}>
          <Typography variant="body2" color={"text.secondary"} sx={{ mb: 2 }}>
            Dates
          </Typography>
          {remainingTasks &&
            remainingTasks.map((item) => {
              return (
                <Grid
                  sx={{ mb: 2 }}
                  container
                  alignItems={"center"}
                  spacing={2}
                >
                  <Grid item xs={1}>
                    <IconButton
                      sx={{ backgroundColor: "#E0E0E0", pointerEvents: "none" }}
                    >
                      <AccessTimeOutlinedIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={11}>
                    <Typography component={"div"}>
                      <Typography variant="body1" gutterBottom>
                        {date}
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.notes.replace(/\n/g, "<br />"),
                          }}
                        />
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
        </Box>
      ) : (
        ""
      )}
    </div>
  );
};

export default CandidateActivities;
