import { Box, Button, Chip, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import { useParams } from "react-router-dom";
import axios from "axios";
// import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';

const genderTypes = {
  M: "Male",
  F: "Female",
  O: "Prefer Not To Say",
};
const CandidateInfo = ({ candidatedetails, resumescrol, coverletterscrol }) => {
  const { id } = useParams();

  // console.log("id",id)
  const datenew = new Date(candidatedetails?.dob).toLocaleDateString();
  // console.log("docs", candidatedetails);
  //  const url = candidatedetails.resume ? candidatedetails.resume : null
  const fileExt = `${candidatedetails?.resume}`.split(".").pop();
  // console.log(fileExt, "fileType");
  const [boxHeight, setBoxHeight] = useState("25vh");
  const [additionalboxheight, setadditionalboxheight] = useState("25vh");
  const boxRef = useRef(null);
  const addRef = useRef(null);

  const docs = [
    {
      uri: `${candidatedetails?.resume_path}`,
      fileType: fileExt,
    },
  ];

  let label =
    candidatedetails?.current_loc?.length > 0
      ? candidatedetails?.current_loc[0]?.label
      : "NA";

  useEffect(() => {
    if (boxRef.current) {
      // Calculate the height of the content and set minHeight accordingly
      const contentHeight = boxRef.current.scrollHeight;
      setBoxHeight(contentHeight > 25 ? `${contentHeight}px` : "25vh");
    }
  }, [candidatedetails.cover_letter]);

  useEffect(() => {
    if (addRef.current) {
      // Calculate the height of the content and set minHeight accordingly
      const addcontentHeight = addRef.current.scrollHeight;
      setadditionalboxheight(
        addcontentHeight > 25 ? `${addcontentHeight}px` : "25vh"
      );
    }
  }, [candidatedetails.additional_info]);

  const handleDownloadPDF = () => {
    const content = boxRef.current;

    var doc = new jsPDF({
      unit: "mm",
      format: "a4",
      orientation: "portrait",
      marginLeft: 10,
      marginRight: 10,
      marginTop: 10,
      marginBottom: 10,
    });

    // Set an appropriate font size
    doc.setFontSize(14); // Adjust the size as needed
    // console.log("content", content.innerText);
    // Split the content into lines (adjust the lineHeight as needed)
    const lineHeight = 5;

    const textcontent = content.innerText.replace(/\n{3}/g, "");
    const textcontents = textcontent.replace("Sincerely,\n", "Sincerely,");
    const lines = doc.splitTextToSize(textcontents, 180);
    // Add lines to the PDF
    lines.forEach((line, index) => {
      doc.text(line, 10, 10 + index * lineHeight);
    });

    // Save and download the PDF
    const fileName = candidatedetails.name
      ? `${candidatedetails.name}_${id}_CoverLetter.pdf`
      : "default_coverLetter.pdf";
    doc.save(fileName);
  };

  const handleDownloadFile = async () => {
    try {
      // Fetch the file content from the URL
      const response = await fetch(candidatedetails.resume_path);

      // Check if the request was successful (status code 200)
      if (!response.ok) {
        throw new Error(`Failed to fetch file: ${response.statusText}`);
      }

      // Get the file content
      const fileContent = await response.text();

      // Create a Blob and download the file
      const blob = new Blob([fileContent], { type: "text/plain" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      // link.download = "file.txt";
      link.download = candidatedetails.name
        ? `${candidatedetails.name}_Resume.txt`
        : "defaultResume.txt";
      link.click();
    } catch (error) {
      console.error("Error downloading file:", error);
      // Handle the error as needed
    }
  };

  return (
    <div>
      <Box sx={{ mb: 5 }}>
        <h4>Basic Details</h4>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
            >
              Name
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="body1"
              className="fw-500"
              sx={{ wordWrap: "break-word" }}
            >
              {candidatedetails.name ? candidatedetails.name : "NA"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
            >
              Gender
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" className="fw-500">
              {candidatedetails.gender
                ? genderTypes[candidatedetails.gender]
                : "NA"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
            >
              Date of Birth
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" className="fw-500">
              {candidatedetails?.dob
                ? new Date(candidatedetails?.dob).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : "NA"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
            >
              Email
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="body1"
              className="fw-500"
              sx={{ wordWrap: "break-word" }}
            >
              {candidatedetails.email ? candidatedetails.email : "NA"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
            >
              Mobile
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" className="fw-500">
              {candidatedetails.mobile ? `+1${candidatedetails.mobile}` : "NA"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
            >
              Current Address
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="body1"
              className="fw-500"
              sx={{ wordWrap: "break-word" }}
            >
              {candidatedetails.address ? candidatedetails.address : "NA"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
            >
              Work Authorization
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" className="fw-500">
              {(candidatedetails?.work_authorization?.work_authorization === 
              "not available" || candidatedetails?.work_authorization?.work_authorization === "Not Available")
                ? "NA"
                : candidatedetails?.work_authorization?.work_authorization}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mb: 5 }}>
        <h4>Professional Details</h4>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
            >
              Total Experience
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" className="fw-500">
              {(!candidatedetails.year && !candidatedetails.month) ||
              (candidatedetails.year === "0" && !candidatedetails.month) ? (
                "NA"
              ) : (
                <>
                  {candidatedetails.year ? candidatedetails.year : "0"}{" "}
                  {candidatedetails.year > 1 ? "years " : "year "}
                  {candidatedetails.month ? candidatedetails.month : "0"}{" "}
                  {candidatedetails.month > 1 ? "months" : "month"}
                </>
              )}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
            >
              Current Employer
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="body1"
              className="fw-500"
              sx={{ wordBreak: "break-all" }}
            >
              {candidatedetails.current_employer
                ? candidatedetails.current_employer
                : "NA"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
            >
              Current Salary
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" className="fw-500">
              {candidatedetails.CTC ? `$ ` + candidatedetails.CTC : "NA"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
            >
              Expected Salary
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" className="fw-500">
              {candidatedetails.Exp_CTC
                ? `$ ` + candidatedetails.Exp_CTC
                : "NA"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
            >
              Current Location
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" className="fw-500">
              {candidatedetails?.current_loc?.length === 0 ? "NA" : label}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
            >
              Preferred Location
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" className="fw-500">
              {/* {candidatedetails &&
                candidatedetails?.preferred_loc?.map((item, index) => {
                  // console.log("himanshu", item?.label);
                  return (
                    (item.label ? (item?.label === "Any Location()" ? "Any Location" : item?.label) : "NA") +
                    (index < candidatedetails?.preferred_loc?.length - 1
                      ? ", "
                      : "")
                  );
                })} */}
                {
  candidatedetails?.preferred_loc && candidatedetails?.preferred_loc.length > 0 ? (
    candidatedetails?.preferred_loc.map((item, index) => {
      // console.log("himanshu", item?.label);
      return (
        (item.label ? (item.label === "Any Location()" ? "Any Location" : item.label) : "NA") +
        (index < candidatedetails?.preferred_loc.length - 1 ? ", " : "")
      );
    }).join('')
  ) : "NA"
}

            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
            >
              Radius
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" className="fw-500">
              {candidatedetails?.radius !== undefined &&
              candidatedetails?.radius !== null
                ? `${candidatedetails?.radius} ${
                    candidatedetails?.radius === 1 ? "Mile" : "Miles"
                  }`
                : "NA"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
            >
              Notice Period
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" className="fw-500">
              {candidatedetails?.notice_period !== undefined &&
              candidatedetails?.notice_period !== null
                ? candidatedetails?.notice_period === 0
                  ? "Immediate Joiner"
                  : `${candidatedetails?.notice_period} ${
                      candidatedetails?.notice_period === 1 ? "Day" : "Days"
                    }`
                : "NA"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
            >
              Last Working Day
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" className="fw-500">
              {candidatedetails?.last_working_day
                ? new Date(
                    candidatedetails?.last_working_day
                  ).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : "NA"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
            >
              Expected Joining Date
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" className="fw-500">
              {candidatedetails?.expected_joining_date
                ? new Date(
                    candidatedetails?.expected_joining_date
                  ).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : "NA"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mb: 5 }}>
        <h4>Other Details</h4>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
            >
              Highest Education
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" className="fw-500">
              {(candidatedetails?.highest_educ?.highest_education ==
              "not available" || candidatedetails?.highest_educ?.highest_education ==
              "Not Available")
                ? "NA"
                : candidatedetails?.highest_educ?.highest_education}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
            >
              Skills
            </Typography>
          </Grid>
          <Grid item xs={8} display={"flex"} gap={1} flexWrap={"wrap"}>
            {candidatedetails?.skill?.length > 0
              ? candidatedetails?.skill.map((item) => (
                  <Stack spacing={1} sx={{ width: "fit-content" }}>
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label={item.label}
                        sx={{ textTransform: "capitalize" }}
                        variant="filled"
                      />
                    </Stack>
                  </Stack>
                ))
              : "NA"}
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
            >
              Linkedin
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="body1"
              sx={{
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {candidatedetails.linkedIn ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#0A66C2" }}
                  href={candidatedetails.linkedIn}
                  title={candidatedetails.linkedIn}
                >
                  {candidatedetails.linkedIn}
                </a>
              ) : (
                "NA"
              )}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="body1"
              color="text.secondary"
              className="fw-500"
            >
              Portfolio Link
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="body1"
              sx={{
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {candidatedetails.portfolio ? (
                <a
                  width={"20%"}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#0A66C2" }}
                  href={candidatedetails.portfolio}
                  title={candidatedetails.portfolio}
                >
                  {candidatedetails.portfolio}
                </a>
              ) : (
                "NA"
              )}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mb: 5 }} id="coverletterscrol">
        {candidatedetails?.cover_letter !== null &&
          candidatedetails?.cover_letter?.trim() !== "" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "center",
              }}
            >
              <Box>
                <Typography variant="h6" className="fw-500 fs-20">
                  Cover Letter
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  onClick={handleDownloadPDF}
                  startIcon={<FileDownloadIcon />}
                >
                  Download Cover letter
                </Button>
              </Box>
            </Box>
          )}
        {candidatedetails?.cover_letter !== null &&
          candidatedetails?.cover_letter?.trim() !== "" && (
            <Box
              // ref={boxRef}
              sx={{
                minHeight: boxHeight,
                border: "1px solid rgba(0, 0, 0, 0.1)",
                borderRadius: "4px",
                mt: 1,
                overflow: "scroll",
              }}
            >
              <div
                ref={boxRef}
                style={{ marginTop: "10px", marginLeft: "10px" }}
                // dangerouslySetInnerHTML={{
                //   __html: candidatedetails.cover_letter,
                // }}
                className="cover-letter-content"
                dangerouslySetInnerHTML={{
                  __html: candidatedetails.cover_letter,
                }}
              />
            </Box>
          )}
      </Box>

      {candidatedetails.resume_path === "" ? (
        <div id="resumescrol">
          <Typography variant="body1" className="fw-500">
            No resume found
          </Typography>
        </div>
      ) : (
        <div id="resumescrol">
          <Box sx={{ mb: 5 }}>
            <Typography
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              pb={2}
            >
              <Typography variant="h6" className="fw-500 fs-20">
                Resume
              </Typography>
              {fileExt === "txt" && (
                <Button
                  variant="contained"
                  onClick={handleDownloadFile}
                  startIcon={<FileDownloadIcon />}
                  sx={{ textTransform: "none" }}
                >
                  Download Resume
                </Button>
              )}
            </Typography>
            {fileExt === "pdf" || fileExt === "txt" || fileExt === "doc" ? (
              <iframe
                src={candidatedetails.resume_path}
                referrerPolicy="no-referrer-when-downgrade"
                seamless="seamless"
                width="100%"
                height="1000px"
                title={candidatedetails.resume}
                frameBorder="0"
                className="border"
              ></iframe>
            ) : (
              <Box sx={{ height: "65vh" }}>
                {docs === null ? (
                  <Typography
                    variant="body1"
                    textAlign={"center"}
                    className="fw-500"
                  >
                    No Resume
                  </Typography>
                ) : (
                  <DocViewer
                    documents={docs}
                    pluginRenderers={DocViewerRenderers}
                    style={{ height: "100%" }}
                    config={{
                      header: {
                        disableHeader: true,
                        disableFileName: true,
                      },
                    }}
                    theme={{
                      disableThemeScrollbar: true,
                    }}
                  />
                )}
              </Box>
            )}
            {candidatedetails.additional_info && (
              <>
                <Typography variant="h6" className="fw-500 fs-20">
                  Additional Info
                </Typography>
                <Box
                  // ref={boxRef}
                  sx={{
                    minHeight: additionalboxheight,
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    mt: 1,
                    overflow: "scroll",
                  }}
                >
                  <div
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                    ref={addRef}
                    dangerouslySetInnerHTML={{
                      __html: candidatedetails.additional_info.replace(
                        /\n/g,
                        "<br>"
                      ),
                    }}
                  />
                </Box>
              </>
            )}
          </Box>
        </div>
      )}
    </div>
  );
};

export default CandidateInfo;
