import React, { useEffect, useState } from 'react';
import './App.css';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
  useNavigate
} from 'react-router-dom';
import JobOrders from './Screens/jobOrders';
import FindTalent from './Screens/findTalent';
import Forgetpassword from './Screens/forgetpassword';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Createpassword from './Screens/createpassword';
import Changepassword from './Screens/changepassword';
import JobDetails from './Screens/jobDetails';
import SearchCandidate from './Screens/searchCandidate';
import Login from './Screens/login';
import { useSelector } from 'react-redux';
import Cookies from "js-cookie";
import Createjob from './Screens/Createjob';
import useAxiosInterceptor from "./interceptors/axios";





function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const navigate = useNavigate()
  const token = localStorage.getItem("token");
  
  const mailtoken = window.location.pathname ?.split('/')[2]?.split("=")[1]

useAxiosInterceptor(); 

useEffect(() => {
    // Check if token exists and set isLoggedIn accordingly
    if (token) {
      setIsLoggedIn(true);
    }
  }, [token]);
  
  
  return (
    <div>
      <BrowserRouter>
       <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
          <Route path="/forgetpassword" element={<Forgetpassword setIsLoggedIn={setIsLoggedIn} />} />
          <Route path={`/createpassword/_secure_ARJ_=${mailtoken}`} element={<Createpassword />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/changepassword" element={<Changepassword />} />
            <Route path="/jobOrders" element={<JobOrders />} />
            <Route path="/Postjob" element={<Createjob />} />
            <Route path="/Find-talents" element={<FindTalent />} />
            <Route path="/jobOrders/:id" element={<JobDetails />} />
            <Route path="/searchCandidate" element={<SearchCandidate />} />
            <Route  element={<Navigate to="/jobOrders" />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer autoClose={2000} position="top-right" theme="colored" />
    </div>
  );
}

function PrivateRoutes({ children }) {
  // const getTokenFromRedux = useSelector((state) => state.reducer.token);
  const tokenFromLocalStorage = useSelector((state) => state.user.token) || Cookies.get("_secure_ARJ_") ;
  const refreshtoken = useSelector((state) => state.user.refreshToken) || Cookies.get("_secure_ref") ;
  // console.log(refreshtoken,"refreshtoken")
  // console.log("token",tokenFromLocalStorage)

  
 // const isAuthenticated = !!getTokenFromRedux || !!tokenFromLocalStorage;
 const isAuthenticated = (refreshtoken || tokenFromLocalStorage)
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return children ? children : <Outlet />;
}

export default App;


