import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Pagination,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, getGridStringOperators } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
// import { useNavigate } from 'react-router-dom';
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CandidateDetails from "./candidateDetails";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
// import styled from "@emotion/styled";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { generate_filter_condition } from "../utiles/filter";
import Questionpop from "./Questionpop";
import _debounce from "lodash/debounce";
import { onGlobalFliterFunc } from "../utiles/onFilterChange";

// const baseURL = process.env.REACT_APP_BASEURL;
const statusColors = {
  Applied: "primary",
  "Employer Applied": "primary",
  Shortlisted: "success",
  "Tech Evaluation": "warning",
  "Manager Evaluation": "info",
  "HR Round": "info",
  "Offer Released": "success",
  Withdrawn: "error",
  Rejected: "error",
};

const verifiedColors = {
  1: "success",
  0: "error",
};
const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 90,
    filterable: false,
  },
  {
    field: "fullName",
    headerName: "Full Name",
    filterable: false,
    width: 200,
    renderCell: (params) => (
      <Typography
        variant="body2"
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
        title={params.row.fullName}
      >
        {" "}
        {params.row.fullName || "NA"}{" "}
      </Typography>
    ),
  },
  {
    field: "entered_by",
    headerName: "Created By",
    width: 180,
    filterOperators: getGridStringOperators().filter(
      (operator) =>
        operator.value === "contains" ||
        operator.value === "equals" ||
        operator.value === "isAnyOf"
    ),
  },
  {
    field: "current_location",
    headerName: "Location",
    filterable: false,
    width: 200,
    renderCell: (params) => (
      <Typography
        variant="body2"
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
        title={params.row.current_location}
      >
        {params.row.current_location || "NA"}
      </Typography>
    ),
  },
  {
    align: "start",
    field: "experience",
    filterable: false,
    headerName: "Experience",
    width: 200,
    renderCell: (params) => {
      const years = params.row.year || 0;
      const months = params.row.month || 0;

      let result = "";

      if (years > 0) {
        result += `${years} year${years > 1 ? "s" : ""}`;
      }

      if (months > 0) {
        result += `${result.length > 0 ? " " : ""}${months} month${
          months > 1 ? "s" : ""
        }`;
      }

      return years === 0 ? "NA" : result.length > 0 ? result : "NA";
    },
  },
  {
    field: "current_salary",
    filterable: false,
    headerName: "Current Salary",
    width: 200,
    renderCell: (params) => params.row.current_salary || "NA",
  },
  {
    field: "last_active",
    filterable: false,
    headerName: "Last Active on",
    width: 247,
    renderCell: (params) => params.row.last_active || "NA",
  },
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const RecommendedTab = (props) => {
  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [applicants, setApplicantsdata] = useState([]);
  const getToken = useSelector((state) => state.user.token);
  const [isApplicanOpen, setApplicanOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [checkboxSelected, setcheckboxSelected] = useState(true);
  const [verifiedstatus, setverifiedstatus] = useState("");
  const [refreshagain, setrefreshagain] = useState(false);
  const [opennew, setOpennew] = useState(false);
  const [sPage, _sPage] = useState(0);
  const [candidatedrawerflag, setcandidatedrawerflag] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [queryOptions, setQueryOptions] = useState({});
  const [shimmer, setshimmer] = useState(false);
  const [isAnyCheckboxSelected, setIsAnyCheckboxSelected] = useState(false);
  const [addToJobCandidateSelection, setAddToJobCandidateSelection] = useState(
    []
  );
  const [addtoJob, setAddtoJob] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [bulkdata, setbulkdata] = useState([]);
  const [adddisbale, setadddisable] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [addtojobcount, setaddtojobcount] = useState(true);
  const [inputValueJobOrder, setInputValueJobOrder] = useState("");
  const [keepSelected, setKeepSelected] = useState(true);
  const [warning, setwarning] = useState(false);
  const [rerenderKey, setRerenderKey] = useState(0);
  const [activitydisable, setactivitydisable] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 18,
  });
  const [showLoader, setShowLoader] = useState(false);
  const [open, setOpen] = React.useState(
    addToJobCandidateSelection.length > 1 ? true : false
  );
  const [filterOptions, filtersetQueryOptions] = React.useState({
    filterModel: null,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [searchTermPrev, setSearchTermPrev] = useState("");

  const handleSortModelChange = React.useCallback((sortModel) => {
    // Here you save the data you need from the sort model
    // console.log('mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm', sortModel, "2222222");
    setQueryOptions({ sortModel: [...sortModel] });
  }, []);

  const handletoprecommendations = () => {
    setRerenderKey((prevKey) => prevKey + 1);
    // Update paginationModel and setQueryOptions
    setPaginationModel({
      ...paginationModel,
      page: 0,
    });
    setQueryOptions({});
    // Perform other actions as needed
  };

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleCloseDialog = () => {
    // Close the dialog
    setOpenDialog(false);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  // const onFilterChange = React.useCallback((filterModel) => {
  //   if (filterModel?.items && filterModel?.items.length > 0) {
  //     const { field, operator, value } = filterModel.items[0];
  //     if (value?.length > 2 || (operator === "isAnyOf" && value?.length > 0)) { // Check if the length of the value is not greater than 3
  //       const filterCondition = generate_filter_condition(field, operator, value);
  //       filtersetQueryOptions({ filterModel: { ...filterModel }, filterCondition });
  //     } else {
  //       // Do something if the length of the value is greater than 3
  //     }
  //   } else {
  //     filtersetQueryOptions({ filterModel: { ...filterModel }, filterCondition: null });
  //   }
  // }, []);

  const onFilterChange = React.useCallback((filterModel) => {
    const respFilterData = onGlobalFliterFunc(filterModel,`and concat(u.first_name, ' ', u.last_name)`);
    if (respFilterData) {
      filtersetQueryOptions(respFilterData);
    }
  }, []);

  useEffect(() => {
    // console.log("queryOptionsqueryOptions", queryOptions);
    setShowLoader(true);
    let payload = {
      jobId: id,
      filterCriteria: filterOptions.filterCondition
        ? filterOptions.filterCondition
        : "",
    };
    if (queryOptions.sortModel && queryOptions.sortModel.length > 0) {
      payload.sortField = queryOptions?.sortModel[0].field;
      payload.sortBy = queryOptions?.sortModel[0].sort;
    }

    axios
      .post(
        `ai-recommendation/candidate-list/${
          searchTermPrev && searchTermPrev !== searchTerm
            ? 1
            : paginationModel.page + 1
        }${searchTerm ? "?search=" + searchTerm : ""}`,
        payload,
        {
          headers: {
            authorization: getToken ? getToken : localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        const filteredApplicants = response.data.message.data.filter(
          (applicant) => applicant.id !== null
        );
        setApplicantsdata(filteredApplicants);
        setShowLoader(false);
        // setaddtojobcount(true);
        setTotalCount(response.data.message.page);
        setSearchTermPrev(searchTerm);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    paginationModel,
    queryOptions,
    searchTerm,
    shimmer,
    isApplicanOpen,
    filterOptions,
  ]);

  const HandleRowClick = (params, event) => {
    setverifiedstatus(params.row.isVerified);
    setSelectedRowData(params.row);
    setApplicanOpen(true);
  };

  // const handleSelection = (params, event) => {
  //     setcheckboxSelected(true);
  //     console.log("1111111111111111111111", params)
  // };

  const handleSelection = (params, event) => {
    const currentSelectedRows = addToJobCandidateSelection;
    if (params.length < 1) {
      setaddtojobcount(true);
    }
    if (params.length >= 10) {
      setwarning(true);
      setAddToJobCandidateSelection(params);
      setaddtojobcount(false);
    }
    if (params.length > 0 && params.length < 10) {
      setAddToJobCandidateSelection(params);
      setIsAnyCheckboxSelected(!!params.length);
      setaddtojobcount(false);
      setwarning(false);
    }
  };

  const filteredApplicants = applicants.filter((row) =>
    Object.values(row).some((value) =>
      value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const handleOnClose = () => {
    setApplicanOpen(false);
    setOpennew((prev) => !prev);
  };

  useEffect(() => {}, []);
  const handleAddCandidates = () => {
    setshimmer(true);
    axios
      .post(
        `bulk-add/job`,
        {
          candidate_ids: addToJobCandidateSelection
            ? addToJobCandidateSelection
            : [],
          job_id: id,
          quesAnsArray: [],
          isShortlisted: 0,
        },
        {
          headers: {
            authorization: getToken ? getToken : localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          // settogglecheckbox(false);
          setKeepSelected(!keepSelected);
          setRerenderKey((prevKey) => prevKey + 1);
          setAddToJobCandidateSelection([]);
          //   setJobOrder("");
          //   setfaliurecount();
          setshimmer(false);
          // console.log(response);
          setbulkdata(response.data.message.details);
          if (response.data.message.failure_candidates >= 1) {
            setadddisable(true);
          }
          handleSnackbarOpen("Candidate Added Successfully");
          if (response.data.message.failure_candidates === 0) {
            setAddtoJob(false);
            setOpenSnackbar(true);
          } else {
            setAddtoJob(true);
          }
        } else {
        }
      })
      .catch((err) => {
        console.log("error");
        handleSnackbarOpen(err.response.data.error);
        // setAddtoJob(false);
      });
  };

  // console.log("questionrequired", props.questionrequired);

  return (
    <div>
      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextField
          sx={{ width: "25ch" }}
          type="search"
          placeholder="Search..."
          id="standard-search"
          variant="standard"
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleAddCandidates}
            disabled={addtojobcount}
            //   disabled={props.status === "Closed" || props.status === "Cancelled" || props.status === "Full"}
          >
            Add to Job
          </Button>
          {/* <Button
            variant="outlined"
            color="secondary"
            size="small"
            startIcon={<AutoAwesomeIcon />}
            onClick={handletoprecommendations}
          >
            Top Recommendations
          </Button> */}
        </Box>
      </Box>
      {/* <DataGrid
              rows={filteredApplicants}
              autoHeight
              columns={columns}
              getRowId={(row) => row.id}
              onRowClick={HandleRowClick}
              checkboxSelection={checkboxSelected}
              paginationMode="server"
              onPaginationModelChange={(page) => getData(page + 1)}
              onRowSelectionModelChange={handleSelection}
              disableRowSelectionOnClick
              className="data-grid-row-hover"
              rowCount={totalCount}
              initialState={{
                pagination: { paginationModel: { pageSize: 1 } },
              }}
          /> */}
      {warning ? (
        <Box sx={{ mb: 1, mt: 1, color: "rgba(0, 108, 183, 1)" }}>
          You can select upto 10 Candidates.
        </Box>
      ) : null}
      <DataGrid
        sx={{
          "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
            {
              display: "none",
            },
          minHeight: filteredApplicants.length > 0 ? 650 : "unset",
          maxHeight: filteredApplicants.length > 0 ? 650 : "unset",
        }}
        keepNonExistentRowsSelected
        columns={columns}
        loading={showLoader}
        autoHeight={filteredApplicants.length === 0}
        key={rerenderKey}
        paginationMode="server"
        rows={filteredApplicants ? filteredApplicants : []}
        getRowId={(row) => row.id}
        onRowClick={HandleRowClick}
        checkboxSelection={checkboxSelected}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[18]}
        rowCount={totalCount}
        sortingMode="server"
        filterMode="server"
        onFilterModelChange={_debounce(onFilterChange, 500)}
        onSortModelChange={handleSortModelChange}
        onRowSelectionModelChange={handleSelection}
        disableRowSelectionOnClick
        isRowSelectable={(params) => {
          if (addToJobCandidateSelection?.length < 10) return true;
          else if (addToJobCandidateSelection?.includes(params?.id))
            return true;
          else {
            return false;
          }
        }}
      />
      <CandidateDetails
        activitydisable={activitydisable}
        setactivitydisable={setactivitydisable}
        verifiedstatus={verifiedstatus}
        candidatedrawerflag={candidatedrawerflag}
        isOpen={isApplicanOpen}
        setApplicanOpen={setApplicanOpen}
        onClose={handleOnClose}
        opennew={opennew}
        setOpennew={setOpennew}
        selectedRowData={selectedRowData}
        setrefreshagain={setrefreshagain}
        refreshagain={refreshagain}
        issearchnavbar={false}
        questionrequired={props.questionrequired}
      />
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle sx={{ color: "red" }}>Error</DialogTitle>
        <DialogContent>
          You cannot select more than 10 Candidates.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={
            snackbarMessage === "Candidate Added Successfully"
              ? "success"
              : "error"
          }
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Required additional questions
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            This job requires screening questions to be answered. We suggest you
            to add candidates one by one for efficient screening.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="error">
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>

      {/* <Questionpop
        quesOpenPopup={quesOpenPopup}
        quesAnsArray={quesAnsArray}
        setQuesAnsArray={setQuesAnsArray}
        handleQuesPopupClose={handleQuesPopupClose}
        handleQuessummit={handlePopUpsCondition}
        joborder_id={props.id}
        textFields={textFields}
        setTextFields = {setTextFields}
        radioValues={radioValues}
        setRadioValues = {setRadioValues}
      /> */}
    </div>
  );
};

export default RecommendedTab;
