import React, { useState } from "react";
import {
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
} from "@mui/material";
import "../App.css";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import Header from "../components/header";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import { getTokenID } from "../redux/modules/userSlice";

// const baseURL = process.env.REACT_APP_BASEURL;
const defaultTheme = createTheme();




const Changepassword = () => {
  const nav = useNavigate();

  const [currentPassword, setCurrentPassword] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const getToken = useSelector((state) => state.user.token);
  const getRefreshToken = useSelector((state) => state.user.refreshToken);

  const [password, setPassword] = useState("");
  // const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [oldPassworderror,setoldPassworderror] =useState('')
  const combinedCurrentPasswordError = currentPasswordError || oldPassworderror;


  const dispatch = useDispatch();
  const handleClickShowCurrentPassword = () =>
    setShowCurrentPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const [passwordError, setPasswordError] = useState("");
  // const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleCurrentPasswordChange = (event) => {
    setCurrentPassword(event.target.value);
  };

  const handleSnackbarOpen = (message) => {
    console.log("Snackbar message:", message);
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    let isValid = true;
    setPasswordError("");
    setConfirmPasswordError("");
    setCurrentPasswordError("");

    if (password.length <= 6) {
      setPasswordError("Password must be more than 6 letters.");
      isValid = false;
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match!");
      isValid = false;
      return;
    }
    axios
      .post(
        `change-password`,
        {
          newPassword: confirmPassword,
          oldPassword: currentPassword,
        },
        {
          headers: {
            authorization: getToken ? getToken : localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
            handleSnackbarOpen("Password Changed Succesfully");
            setTimeout(() => {
              nav('/login');
            },1500);
        // Clear localStorage
        setTimeout(() => {
          localStorage.clear();
          dispatch(getTokenID(""));
          localStorage.removeItem("token");
        }, 3000);
        } else {
            handleSnackbarOpen(response.data.error);
        }  
      })
      .catch((err) => {
        console.log(err);
        handleSnackbarOpen(err.response.data.error);
        setoldPassworderror(true);
        
      });
  };
  console.log("passwords", password, currentPassword, confirmPassword);
  return (
    <div>
      <Header />
      <ThemeProvider theme={defaultTheme}>
        <Container maxWidth="xl">
          <Box
            sx={{
              px: { xs: 1, sm: 2, md: 15, lg: 25 },
              marginTop: 5,
              
            }}
          >
            <Typography
              textAlign={"start"}
              component="h1"
              sx={{ my: 2 }}
              variant="h4"
            >
              Change Password
            </Typography>
            <Box
              component="form"
              maxWidth={"sm"}
              // onSubmit={(e) => {
              //   nav("/jobOrders");
              // }}
              sx={{ mt: 1 }}
            >
              <FormControl fullWidth sx={{ mt: 2 }} variant="outlined">
                <InputLabel size="small" htmlFor="outlined-adornment-password">
                  Current Password
                </InputLabel>
                <OutlinedInput
                  margin="dense"
                  id="outlined-adornment-password"
                  size="small"
                  type={showCurrentPassword ? "text" : "password"}
                  value={currentPassword}
                  error={!!currentPasswordError || oldPassworderror}
                  onFocus={() => setoldPassworderror('')}
                  onChange={handleCurrentPasswordChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowCurrentPassword}
                        edge="end"
                      >
                        {showCurrentPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Current Password"
                />
                <FormHelperText error>{combinedCurrentPasswordError}</FormHelperText>
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }} variant="outlined">
                <InputLabel size="small" htmlFor="outlined-adornment-password">
                  New Password
                </InputLabel>
                <OutlinedInput
                  margin="dense"
                  id="outlined-adornment-password"
                  size="small"
                  type={showNewPassword ? "text" : "password"}
                  value={password}
                  error={!!passwordError}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="New Password"
                />
                <FormHelperText error>{passwordError}</FormHelperText>
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }} variant="outlined">
                <InputLabel size="small" htmlFor="outlined-adornment-password">
                  {" "}
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  margin="dense"
                  id="outlined-adornment-password"
                  size="small"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  error={!!confirmPasswordError}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Confirm Password"
                />
                <FormHelperText error>{confirmPasswordError}</FormHelperText>
              </FormControl>
              <Grid container sx={{ mt: 2 }}>
                <Grid xs>
                  <Button
                    href="/forgetpassword"
                    variant="text"
                    sx={{
                      textTransform: "capitalize",
                      textDecoration: "underline",
                    }}
                    component="button"
                  >
                    Forgot password
                  </Button>
                </Grid>
              </Grid>
              <Stack sx={{ width: "100%", my: 4 }} spacing={2}>
                <Alert severity="warning">
                  You will be logged out of all active sessions.
                </Alert>
              </Stack>
              <Button
                type="submit"
                variant="text"
                sx={{ py: 1 }}
                onClick={(e) => {
                  nav("/jobOrders");
                }}
              >
                Go Back
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{ mx: 3, py: 1, px: 5, borderRadius: "50rem" }}
                onClick={handleSubmit}
                disabled={
                  currentPassword === "" ||
                  password === "" ||
                  confirmPassword === ""
                }
              >
                Save
              </Button>
            </Box>
          </Box>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={
                snackbarMessage === "Password Changed Succesfully" ? "success" : "error"
              }
              sx={{ width: "100%" }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default Changepassword;
